import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Divider from "@mui/material/Divider";
import { Card, Alert, RadioGroup, FormControlLabel, Radio, Button, Link, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { blue } from '@mui/material/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { useBrand } from 'layouts/utils/BrandContext';
import { useDataConfiguration } from 'hooks/useDataConfiguration';

const RefreshDetails = forwardRef((props, ref) => {
    const { selectedBrand, selectedRefresh, setSelectedRefresh, configurationData, setConfigurationData, saveContext, loadContext} = useBrand();
    const [filesWithVersions, setFilesWithVersions] = useState([]);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [loading, setLoading] = useState(false);
    const { brandName, refreshName } = useParams();
    const navigate = useNavigate();
    const auth = useAuthInfo();

    useImperativeHandle(ref, () => ({
       fetchFilesVersions,
    }));

    const {
        fetchDataConfigurationUserSelection,
        getUiConfigurationValues
    } = useDataConfiguration();

    useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
    }, [brandName, refreshName, loadContext]);

    useEffect(() => {
        if (selectedRefresh?.selectedFile) {
            setSelectedVersion(selectedRefresh.selectedFile.version);
        }
    }, [selectedRefresh]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const fetchFilesVersions = useCallback(async () => {
        if (!selectedRefresh) {
            return;
        }
        setLoading(true);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/get_files_versions?brand_name=${encodeURIComponent(selectedBrand.brand_name)}&refresh_created_at=${encodeURIComponent(selectedRefresh.created_at)}`;
        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            });
            if (!response.data.Files.validation_error) {
                setFilesWithVersions(response.data.Files);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    }, [auth.accessToken, selectedBrand, selectedRefresh]);

    useEffect(() => {
        fetchFilesVersions();
    }, [fetchFilesVersions]);

    const handleVersionSelect = async(file, version) => {
        // remove local userSelection storage
        localStorage.removeItem('storedUserSelection');

        const selectedFile = { file, version };
        const updatedRefresh = {
            ...selectedRefresh,
            selectedFile: selectedFile,
        };
        setSelectedRefresh(updatedRefresh);
        setSelectedVersion(version); // Store the version index
        saveContext({
            selectedBrand,
            selectedRefresh: updatedRefresh,
            configurationData: null
        });

        const formData = new FormData();
        formData.append('brand_name', selectedBrand.brand_name);
        formData.append('refresh_created_at', selectedRefresh.created_at);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            const fullSalesUrl = `${baseUrl}/brands/remove_user_selection`;
            await axios.post(fullSalesUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            })
        } catch (error) {
            let errorMessage = 'Error removing refresh user selection';
            if (error.response && error.response.data && error.response.data.detail) {
                errorMessage = `Error: ${error.response.data.detail}`;
            } else if (error.message) {
                errorMessage = `Error: ${error.message}`;
            }
            console.error('Error removing refresh user selection - ', error);
            setAlert({ show: true, message: errorMessage, color: 'error' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleConfigureData = async () =>  {
        const configurationDataUserSelection = await fetchDataConfigurationUserSelection();
        const isUserSelectionEmpty = configurationDataUserSelection && Object.values(configurationDataUserSelection.UserSelection).every(value => Array.isArray(value) && value.length === 0);

       if (!configurationDataUserSelection || isUserSelectionEmpty) {
            setLoading(true);
            const formData = new FormData();
            formData.append('brand', selectedBrand.brand_name);
            formData.append('refresh_name', selectedRefresh.refresh_name);
            formData.append('file_url', selectedRefresh.selectedFile.file.file_url);
            formData.append('version', selectedRefresh.selectedFile.version);

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            try {
                const fullSalesUrl = `${baseUrl}/data_configuration/generate_data_configuration_file`;
                const response = await axios.post(fullSalesUrl, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${auth.accessToken}`
                    }
                })
                if (response.data.validation_error) {
                    setAlert({ show: true, message: response.data.validation_error, color: 'error' });
                    setOpenDialog(true);
                } else {
                   const data = response.data
                   setConfigurationData(data)
                   const contextData = {
                      selectedBrand,
                      selectedRefresh: selectedRefresh,
                      configurationData: data,
                    };
                    navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-configuration`);
                }
            } catch (error) {
                let errorMessage = 'Error generating the configuration_ui.json file';
                if (error.response && error.response.data && error.response.data.detail) {
                    errorMessage = `Error generating the configuration_ui.json file: ${error.response.data.detail}`;
                } else if (error.message) {
                    errorMessage = `Error generating the configuration_ui.json file: ${error.message}`;
                }
                console.error('Error generating the configuration_ui.json file - ', error);
                setAlert({ show: true, message: errorMessage, color: 'error' });
                setOpenDialog(true);
            } finally {
                setLoading(false);
            }
        } else {
            getUiConfigurationValues(configurationDataUserSelection);
            navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-configuration`);
        }
    };

    const handleFileUpload = async (file, file_url) => {
        console.log('handleFileUpload', file, file_url);
        if (!file) return;

        const fileExtension = file.name.split('.').pop().toLowerCase();
        const urlFileExtension = file_url.split('.').pop().toLowerCase();

        if (fileExtension !== urlFileExtension) {
            setAlert({ show: true, message: 'File extension does not match the existing file.', color: 'error' });
            setOpenDialog(true);
            return;
        }

        const display_name = file.name;
        const original_filename = file_url.split('/').pop();
        const renamedFile = new File([file], original_filename, { type: file.type });

        const formData = new FormData();
        formData.append('file', renamedFile);
        formData.append('file_url', file_url);
        formData.append('display_name', display_name);

        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/upload_file_version`;

        try {
            const response = await axios.post(fullUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            if (response.data) {
                setAlert({ show: true, message: 'File uploaded successfully!', color: 'success' });
                setOpenDialog(true);
                fetchFilesVersions();
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
            setOpenDialog(true);
        }
    };

    const handleFileDelete = async (file, brand_name, refresh_name) => {
        console.log('SElected version: ', selectedVersion);
       if (selectedVersion === null) {
            setAlert({ show: true, message: 'No version selected for deletion.', color: 'error' });
            setOpenDialog(true);
            return;
        }
        console.log('File version to delete:',  file, selectedVersion);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/delete_file_version`;

        const formData = new FormData();
        formData.append('file_url', file.file_url);
        formData.append('version', selectedVersion);
        formData.append('brand_name', brand_name);
        formData.append('refresh_name', refresh_name);
        try {
            const response = await axios.post(fullUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            });
            if (response.data) {
                setAlert({ show: true, message: 'File version deleted successfully!', color: 'success' });
                setOpenDialog(true);
                setSelectedVersion(null);
                fetchFilesVersions();
            }
        } catch (error) {
            console.error('Error deleting file version:', error);
            setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
            setOpenDialog(true);
        }
    };

    const handleBack = () => {
        console.log('selectedBrand: ', selectedBrand);
        if (selectedBrand && selectedBrand?.brand_name !== 'undefined') {
            navigate(`/brand-manager/${selectedBrand.brand_name}`);
        } else {
            navigate(`/brand-manager`);
        }
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {loading ? (
                <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress sx={{ color: blue[500] }} />
                    <MDTypography variant="normal" sx={{ mt: 2 }}>
                        Loading data for <strong>{selectedRefresh?.refresh_name}</strong>...
                    </MDTypography>
                </MDBox>
            ) : (
                <>
                <Card sx={{ mt: 2, p: 1 }}>
                    <MDBox p={2} >
                        { selectedRefresh ? (
                            <>
                             <MDTypography variant="body2" sx={{ mt: 1 }}>
                                     Refresh <strong>{selectedRefresh ? selectedRefresh.refresh_name : "None"}</strong> for brand <strong>{selectedBrand.brand_name}</strong>
                            </MDTypography>
                            <MDTypography variant="body2" sx={{ mt: 1 }}>
                                 {selectedRefresh?.created_at ? format(new Date(selectedRefresh.created_at), 'MMMM do, yyyy h:mm a') : 'Invalid date'} (UTC)
                            </MDTypography>
                            </>
                            ) : (
                                <MDTypography variant="normal">Please select a <b><a href="/brand-manager" style={{ textDecoration: 'none', color: 'blue' }}>Brand</a></b> first and a Refresh</MDTypography>
                        )}
                    </MDBox>
                    <ul style={{ paddingLeft: '30px' }}>
                        {filesWithVersions.map((file, index) => (
                            <li key={index}>
                                <MDTypography variant="normal" sx={{ fontWeight: 'bold' }}>
                                    {file.file_name}
                                </MDTypography>
                                <RadioGroup
                                    name={`file-version-${index}`}
                                    value={
                                        selectedRefresh?.selectedFile?.file?.file_url === file.file_url
                                            ? selectedRefresh?.selectedFile?.version
                                            : ''
                                    }
                                    onChange={(e) => handleVersionSelect(file, e.target.value)}
                                >
                                    {file.versions.map((version, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            value={version.version}
                                            control={<Radio sx={{
                                                '&.Mui-checked': {
                                                    color: '#344767',
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: '#344767',
                                                }
                                            }} />}
                                            label={(
                                                <div>
                                                    <span>{version.display_name} - {format(new Date(version.time_created), 'MMM do, yyyy h:mm a')} (UTC) -</span>
                                                    <Link href={`${file.file_url}?generation=${version.version}`} target="_blank" rel="noopener noreferrer" sx={{ marginLeft: 1, color: 'blue' }}>
                                                        Download file
                                                    </Link>
                                                </div>
                                            )}
                                            sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}
                                        />
                                    ))}
                                </RadioGroup>
                                <MDBox sx={{ display: 'flex', justifyContent: 'left', gap: 2, mt: 3, mb: 3 }}>
                                    <MDButton
                                        variant="contained"
                                        color="dark"
                                        component="label"
                                        sx={{ marginTop: 2 }}
                                    >
                                        Upload New Version
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(e) => handleFileUpload(e.target.files[0], file.file_url)}
                                        />
                                    </MDButton>
                                    <MDButton
                                        variant="contained"
                                        color="dark"
                                        sx={{ marginTop: 2 }}
                                        onClick={() => handleFileDelete(file, selectedBrand.brand_name, selectedRefresh.refresh_name)}
                                        disabled={!selectedRefresh.selectedFile || !selectedVersion}
                                    >
                                        Delete Version
                                    </MDButton>
                                </MDBox>
                            </li>
                        ))}
                    </ul>
                    <Divider />
                        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
                            <MDButton onClick={handleBack} color="dark" variant="contained" sx={{ marginLeft: '10px' }}>
                                Back to Refreshes
                            </MDButton>
                            <MDBox flexGrow={1} display="flex" justifyContent="center">
                                <MDButton component={Link} color="dark" onClick={handleConfigureData} disabled={ !selectedRefresh?.selectedFile || !selectedVersion }>
                                    Configure data
                                </MDButton>
                            </MDBox>
                        </MDBox>
                 </Card>
                </>
            )}
        </>
    );
});

export default RefreshDetails;
