import { useState, useEffect } from "react";
import { useAuthInfo } from "@propelauth/react";
import { Tooltip, Card, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, CircularProgress, TextField, IconButton, Icon } from '@mui/material';
import { blue } from '@mui/material/colors';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { lightBlue } from '@mui/material/colors';
import { useBrand } from 'layouts/utils/BrandContext';
import { useParams } from 'react-router-dom';
import Chart from 'react-apexcharts';
import axios from 'axios';

function DataReadinessTab() {
    const { selectedBrand, selectedRefresh, user_email, loadContext } = useBrand();
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [readinessResults, setReadinessResults] = useState(null);
    const { brandName, refreshName } = useParams();
    const auth = useAuthInfo();

    useEffect(() => {
        const fetchContext = async () => {
            if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
                await loadContext(brandName, refreshName);
            }
        };
        fetchContext();
    }, [brandName, refreshName, loadContext]);

    // Automatically call handleDataReadiness when context is set
    useEffect(() => {
        if (selectedBrand && selectedRefresh) {
            handleDataReadiness();
        }
    }, [selectedBrand, selectedRefresh]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDataReadiness = async () => {
        setLoading(true);
        const model_inputs_path = `${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/model_inputs.json`;
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/data_configuration/check_data_readiness`;

        const formData = new FormData();
        formData.append('model_inputs_path', model_inputs_path);
        try {
            const response = await axios.post(fullUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            });
             const data = response.data;
            if (data.validation_error) {
                // Set alert to show the validation error and mark it as HTML content
                setAlert({ show: true, message: data.validation_error, color: 'error' });
                setOpenDialog(true);
            } else {
                setReadinessResults(data);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.validation_error || 'An unexpected error occurred';
            setAlert({ show: true, message: errorMessage, color: 'error' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const chartOptions = {
        chart: {
            type: 'radialBar',
            offsetY: -20
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                hollow: {
                    size: '70%',
                },
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 5,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 10,
                        fontSize: '22px',
                        formatter: (val) => `${val}%`,
                        color: readinessResults?.final_score >= 75 ? '#00e396' :
                               readinessResults?.final_score >= 50 ? '#FFC107' : '#ff4560'
                    }
                }
            }
        },
        fill: {
            colors: readinessResults?.final_score >= 75 ? ['#00e396'] : // Green for >= 75%
                    readinessResults?.final_score >= 50 ? ['#FFC107'] : // Yellow for 50-74%
                    ['#ff4560'] // Red for < 50%
        },
        labels: ['Score'],
    };

    const chartSeries = readinessResults ? [readinessResults.final_score.toFixed(2)] : [0];

    return (
       <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{alert.color === 'success' ? 'Success' : 'Warning'}</DialogTitle>
                <DialogContent>
                    {alert.show && (
                       <div dangerouslySetInnerHTML={{ __html: alert.message }} />  // Render HTML message safely
                    )}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                  <Button onClick={handleCloseDialog} color="primary" sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>
                    Close
                  </Button>
                </DialogActions>
            </Dialog>

          <MDBox p={3} pb={3} pr={5}>
            <MDTypography variant="h3">Data Readiness</MDTypography>

            <Card sx={{ mt: 2, pb: 2, pl: 3, pr: 3, pt: 0 }}>
                {!selectedBrand || !selectedRefresh ? (
                    <MDTypography variant="h6" sx={{ color: 'orange', mt: 3 }}>
                        Please select a Brand and Refresh first in order to view the data readiness.
                    </MDTypography>
                ) : loading ? (
                    <MDBox sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', minHeight: '200px' }}>
                        <CircularProgress sx={{ color: blue[500] }} />
                    </MDBox>
                ) : alert.show && alert.color === 'error' ? (
                    // Use dangerouslySetInnerHTML for HTML rendering inside MDTypography
                    <MDTypography variant="h6" sx={{ color: 'orange', mt: 3 }}>
                        <div dangerouslySetInnerHTML={{ __html: alert.message }} />
                    </MDTypography>
                ) : readinessResults ? (
                    <>
                        <MDTypography variant="h5" sx={{ color: blue[700], textAlign: 'center', fontWeight: 'bold', mt: 2 }}>
                            Final Score: {readinessResults.final_score.toFixed(2)}%
                        </MDTypography>
                        <MDBox sx={{ mt: 3 }}>
                            <Chart
                                options={chartOptions}
                                series={chartSeries}
                                type="radialBar"
                                height="200"
                            />
                        </MDBox>
                        <MDBox sx={{ mt: 0 }}>
                            {readinessResults.readiness_results.map(([category, details], index) => (
                                <Card key={index} sx={{ p: 2, mb: 2 }}>
                                    <MDTypography variant="h5">
                                        {category}
                                        <Tooltip title={details.importance}>
                                            <IconButton size="small">
                                                <Icon sx={{ fontSize: 20 }}>help_outline</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </MDTypography>
                                    <MDBox sx={{ mt: 1 }}>
                                       {details.messages.map((message, idx) => (
                                            <MDTypography
                                                key={idx}
                                                variant="normal"
                                                sx={{ color: message.type === 'success' ? 'green' : message.type === 'warning' ? 'orange' : 'red' }}
                                            >
                                                {message.text}
                                            </MDTypography>
                                        ))}
                                    </MDBox>
                                </Card>
                            ))}
                        </MDBox>
                    </>
                ) : null}
            </Card>
        </MDBox>
        </>
    );
}

export default DataReadinessTab;
