import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import DeckBuilderTab from 'layouts/insight-deck-builder/components/DeckBuilderTab';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { useBrand } from 'layouts/utils/BrandContext';

function InsightDeckBuilder() {
  const navigate = useNavigate();
  const { selectedBrand, selectedRefresh } = useBrand();
  const { brandName, refreshName, tab } = useParams();

  // Define tab indexes based on the tab names in the URL
  const tabMapping = {
    deckBuilder: 0,
  };

  const [tabValue, setTabValue] = useState(tabMapping[tab] || 0);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    // Get the key from the tabMapping and navigate to the correct URL
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/insight-deck-builder/${brandName}/${refreshName}/${selectedTab}`);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

   const breadcrumb = [
      ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{ label: 'Brand: No Brand Selected', link: null }]),
      ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand?.brand_name}/${selectedRefresh.refresh_name}` }] : [{ label: 'Refresh: No Refresh Selected', link: null }]),
      ...(selectedRefresh && selectedRefresh.selectedFile ? [{ label: `File: ${selectedRefresh.selectedFile.file.file_name}`, link: null }] : [{ label: 'File: No File Selected', link: null }])
  ];

 return (
    <DashboardLayout>
      <DashboardNavbar />

            <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Insight Deck Builder"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>analytics</Icon> }
                  style={tabStyle(0)}
                />
              </Tabs>
        <BreadcrumbsComponent breadcrumb={breadcrumb}/>
            </AppBar>
            {tabValue === 0 && <DeckBuilderTab />}
      <Footer />
    </DashboardLayout>
  );
}

export default InsightDeckBuilder;
