import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthInfo } from "@propelauth/react";
import Card from "@mui/material/Card";
import { Divider, Alert, DialogContent, DialogTitle, Dialog, DialogActions, Button } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import axios from 'axios';
import DateVariable from "layouts/data-configuration/components/DateVariable";
import SalesMetric from "layouts/data-configuration/components/sales/SalesMetric";
import SalesPrice from "layouts/data-configuration/components/sales/SalesPrice";
import SalesVariable from "layouts/data-configuration/components/sales/SalesVariable";
import SalesOthers from "layouts/data-configuration/components/sales/SalesOthers";
import SalesChannelNames from "layouts/data-configuration/components/sales/SalesChannelNames";
import AdsVariable from "layouts/data-configuration/components/advertising/AdsVariable";
import AdsChannels from "layouts/data-configuration/components/advertising/AdsChannels";
import AdsSpendMetric from "layouts/data-configuration/components/advertising/AdsSpendMetric";
import AdsModeledMetric from "layouts/data-configuration/components/advertising/AdsModeledMetric";
import OrganicVariables from "layouts/data-configuration/components/organic/OrganicVariables";
import UncontrollableVariables from "layouts/data-configuration/components/uncontrollable/UncontrollableVariables";
import { useBrand } from 'layouts/utils/BrandContext';

const DataConfiguration = () => {
  const navigate = useNavigate();
  const auth = useAuthInfo();
  const [openDialog, setOpenDialog] = useState(false);
  const { brandName, refreshName } = useParams();
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const { selectedBrand, selectedRefresh, configurationData, setConfigurationData, saveContext, loadContext } = useBrand();

  useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedRefresh && !selectedBrand) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext(); // Call the async function
   }, [brandName, refreshName, loadContext]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDataReadiness = (e) => {
    saveContext({ configurationData, selectedRefresh });
    if (selectedRefresh.selectedFile) {
        navigate(`/data-hub/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/dataReadiness`);
    } else {
        setAlert({ show: true, message: 'No file selected for data readiness', color: 'error' });
        setOpenDialog(true);
        return;
    }
  };

  const handleDataValidation = (e) => {
    saveContext({ configurationData, selectedRefresh });
    if (selectedRefresh.selectedFile) {
        navigate(`/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/sales`);
    } else {
        setAlert({ show: true, message: 'No file selected for data validation', color: 'error' });
        setOpenDialog(true);
        return;
    }
  };

  const handleModelingSetup = (e) => {
    saveContext({ configurationData, selectedRefresh });
    if (selectedRefresh.selectedFile) {
         navigate(`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelingSetup`);
    } else {
      setAlert({ show: true, message: 'No file selected for modeling', color: 'error' });
      setOpenDialog(true);
      return;
    }
  };

  const salesData = configurationData?.sales_data || {};
  const adsData = configurationData?.ads_data || {};
  const othersData = configurationData?.others_data || {};

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Alert color={alert.color}>{alert.message}</Alert>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <MDBox p={1}>
        <MDTypography variant="h3">Data Configuration</MDTypography>
        <MDTypography component="div" sx={{ m: 0, width: '100%', textAlign: 'center' }} variant="normal">
          Brand: <strong>{selectedBrand?.brand_name ? selectedBrand.brand_name : 'NA'}</strong> - Refresh: <strong>{selectedRefresh?.refresh_name ? selectedRefresh.refresh_name : 'NA'}</strong>
            <MDBox sx={{ textAlign: 'center' }}>File: <strong>{selectedRefresh?.selectedFile ? selectedRefresh.selectedFile?.file.file_name : "None"}</strong> </MDBox>
        </MDTypography>
        <br />
        <Card sx={{ p: 2 }}>
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Date</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your date variable</MDTypography>
          <DateVariable dateColumns={salesData.date_columns} />
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Sales Metrics</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your sales channels and metrics</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <SalesVariable variables={salesData.dimension_columns} />
            <SalesChannelNames channels_variable={salesData.channels} />
            <SalesMetric metric_variable={salesData.metric_columns} />
            <SalesPrice price_variable={salesData.price_columns} />
            <SalesOthers others_variable={salesData.others_columns} />
          </MDBox>
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Advertising</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your advertising variables and metrics</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <AdsVariable ads_variable={adsData.dimension_columns} />
            <AdsChannels ads_channels={adsData.ads_channels} />
            <AdsSpendMetric ads_spend_metric={adsData.metric_columns} />
            <AdsModeledMetric ads_modeled_metric={adsData.metric_columns} />
          </MDBox>
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Organic Advertising</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your unpaid variables</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <OrganicVariables organic_variables={othersData.organic_variables} />
          </MDBox>
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Uncontrollable Metrics</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select variables that are outside of your control</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <UncontrollableVariables uncontrollable_variables={othersData.uncontrollable_variables} />
          </MDBox>
        <MDBox sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, mb: 2 }}>
          <MDButton variant="contained" color="dark" onClick={handleDataReadiness}>Data Readiness</MDButton>
          <MDButton variant="contained" color="dark" onClick={handleDataValidation}>Data Validation</MDButton>
          <MDButton variant="contained" color="dark" onClick={handleModelingSetup}>Start Modeling</MDButton>
        </MDBox>
        </Card>
      </MDBox>
    </>
  );
};

export default DataConfiguration;
