import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useLogoutFunction, useRedirectFunctions } from '@propelauth/react';

function Cover( {isLoggedIn} ) {
  const logoutFn = useLogoutFunction();
  const { redirectToLoginPage } = useRedirectFunctions();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/brand-manager");
  };

  return (
  <BasicLayout image="">
      <Card>
         <MDTypography  p={2} variant="body2" justifyContent="center" align="center">
            Are you sure you want to log out?
         </MDTypography>
         <MDBox pb={2} display="flex" gap={3} justifyContent="center" alignItems="center">
           <MDButton onClick={handleCancel} color="dark">Cancel</MDButton>
           <MDButton component={Link} onClick={logoutFn}  color="dark">Log out</MDButton>
         </MDBox>
      </Card>
    </BasicLayout>
    );
}

export default Cover;
