import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ChatBar from 'layouts/ChatBar/ChatBar';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import OthersSummaryTable from './OthersSummaryTable';
import OthersGraph from './OthersGraph';
import GetFileData from './../GetFileData';
import { useBrand } from 'layouts/utils/BrandContext';

const OthersTab = () => {
    const { loadContext, selectedBrand, selectedRefresh } = useBrand();
    const { brandName, refreshName, tabV } = useParams();

    const [data, setData] = useState(() => {
        const savedData = localStorage.getItem('othersData');
        return savedData ? JSON.parse(savedData) : null;
    });

    // Update localStorage when data changes
    useEffect(() => {
        localStorage.setItem('othersData', JSON.stringify(data));
    }, [data]);

    const onGetData = useCallback((sheetName, data) => {
        setData(data);
        console.log("Data: ", data);
    }, []);

    return (
      <>
        <MDBox>
            <MDTypography variant="h3" >
                Others
            </MDTypography>
        </MDBox>
        <br/>
      <Card>
          <MDBox pt={3} px={2} height="300px">
            <MDTypography variant="normal" fontWeight="normal">
                Coming soon...
            </MDTypography>
          </MDBox>
       </Card>
      <br />
    </>
    );
};

export default OthersTab;