import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import ChatBar from 'layouts/ChatBar/ChatBar';
import MarketingSummaryTable from './MarketingSummaryTable';
import MarketingGraph from './MarketingGraph';
import GetFileData from './../GetFileData';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';
import { useBrand } from 'layouts/utils/BrandContext';

const MarketingTab = () => {
  const { loadContext, selectedBrand, selectedRefresh } = useBrand();
  const { brandName, refreshName, tab } = useParams();
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem('marketingData');
    return savedData ? JSON.parse(savedData) : null;
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
  }, [brandName, refreshName, loadContext]);

  // Update localStorage when data changes
  useEffect(() => {
    if (data) {
      localStorage.setItem('marketingData', JSON.stringify(data));
    }
  }, [data]);

  const onGetData = useCallback((sheetName, data) => {
    setData(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedRefresh?.selectedFile) {
      setLoading(true);
      setData(null);
    }
  }, [selectedRefresh?.selectedFile]);

  // Effect to handle errors and DOM checks
  useEffect(() => {
     if (error) {
        console.error('Error:', error);
     }
  }, [error]);

  return (
    <>
      <MDBox >
        <MDTypography variant="h3">
          Marketing
        </MDTypography>
        {selectedRefresh?.selectedFile && (
          <GetFileData
            sheetName="Advertising"
            onGetData={onGetData}
            file={selectedRefresh?.selectedFile}
          />
        )}
        {loading ? (
          <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress sx={{ color: blue[500] }} />
             <MDTypography variant="h6" sx={{ mt: 2 }}>
                 Loading the Marketing graph for {selectedRefresh?.selectedFile ? selectedRefresh?.selectedFile.file.file_name : 'the selected file'}...
            </MDTypography>
          </MDBox>
        ) : (
            selectedRefresh?.selectedFile && data && (
              <>
                <MarketingSummaryTable data={data} />
                <br />
                <MarketingGraph data={data} />
              </>
              )
        )}
      </MDBox>
    </>
  );
};

export default MarketingTab;
