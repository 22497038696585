import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MDBox from "components/MDBox";
import HomeIcon from '@mui/icons-material/Home';

const BreadcrumbsComponent = ({ breadcrumb }) => {
  const navigate = useNavigate();

  return (
     <MDBox sx={{ pt: 2, pl: 0, pb:1 }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Typography color="inherit" variant="normal">
            <Link
              color="inherit"
              onClick={() => navigate("/brand-manager")}
              sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', cursor: 'pointer' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
              Home
            </Link>
          </Typography>

          {breadcrumb.map((item, index) => (
            <Typography key={index} color="inherit" variant="normal">
              {item.link ? (
                <Link
                  color="inherit"
                  onClick={() => navigate(item.link)}
                  sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                >
                  {item.label}
                </Link>
              ) : (
                <span sx={{ fontWeight: 'bold' }}>
                  {item.label}
                </span>
              )}
            </Typography>
          ))}
        </Breadcrumbs>
     </MDBox>
  );
};

export default BreadcrumbsComponent;
