import { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExecSummaryTab from 'layouts/ai-insights/components/ExecSummaryTab';
import ROIResultsTab from 'layouts/ai-insights/components/ROIResultsTab';
import ModelStatisticsTab from 'layouts/ai-insights/components/ModelStatisticsTab';
import KeyRecommendationsTab from 'layouts/ai-insights/components/KeyRecommendationsTab';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import { useBrand } from 'layouts/utils/BrandContext';

function AIInsights() {
  const { loadContext, selectedBrand, selectedRefresh } = useBrand();
  const { brandName, refreshName, tab } = useParams();
  const navigate = useNavigate();

  const tabMapping = {
    exec: 0,
    roi: 1,
    stats: 2,
    key: 3
  };

 const [tabValue, setTabValue] = useState(tabMapping[tab] || 0);

 const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/ai-insights/${brandName}/${refreshName}/${selectedTab}`);
 };

 useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
  }, [brandName, refreshName, loadContext]);

  const tabStyle = useCallback((index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  }), [tabValue]);

  const breadcrumb = [
      ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{ label: 'Brand: No Brand Selected', link: null }]),
      ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand?.brand_name}/${selectedRefresh.refresh_name}` }] : [{ label: 'Refresh: No Refresh Selected', link: null }]),
      ...(selectedRefresh && selectedRefresh.selectedFile ? [{ label: `File: ${selectedRefresh.selectedFile.file.file_name}`, link: null }] : [{ label: 'File: No File Selected', link: null }])
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AppBar position="static">
        <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Executive Summary"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>summarize</Icon>}
            style={tabStyle(0)}
          />
          <Tab
            label="ROI Results"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>dataset</Icon>}
            style={tabStyle(1)}
          />
          <Tab
            label="Model Statistics"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>show_chart</Icon>}
            style={tabStyle(2)}
          />
           <Tab
            label="Key Recommendations"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>vpn_key</Icon>}
            style={tabStyle(3)}
          />
        </Tabs>
      <BreadcrumbsComponent breadcrumb={breadcrumb}/>
      </AppBar>
         {tabValue === 0 && <ExecSummaryTab refresh={selectedRefresh} />}
         {tabValue === 1 && <ROIResultsTab refresh={selectedRefresh} />}
         {tabValue === 2 && <ModelStatisticsTab refresh={selectedRefresh} />}
         {tabValue === 3 && <KeyRecommendationsTab refresh={selectedRefresh} />}
      <Footer />
    </DashboardLayout>
  );
}

export default AIInsights;
