import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Box, Typography, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import { useNavigate } from 'react-router-dom';
import Footer from "assets/Footer";
import SalesTab from 'layouts/data-validation/components/SalesTab';
import MarketingTab from 'layouts/data-validation/components/MarketingTab';
import OthersTab from 'layouts/data-validation/components/OthersTab';
import DataLogTab from 'layouts/data-validation/components/DataLogTab';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import { useBrand } from 'layouts/utils/BrandContext';

function DataValidation() {
  const { selectedBrand, selectedRefresh, selectedFile } = useBrand();
  const { brandName, refreshName, tab } = useParams();
  const navigate = useNavigate();

  // Define tab indexes based on the tab names in the URL
  const tabMapping = {
    sales: 0,
    marketing: 1,
    others: 2,
    dataLog: 3,
  };

  const [tabValue, setTabValue] = useState(tabMapping[tab] || 0);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    // Get the key from the tabMapping and navigate to the correct URL
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/data-validation/${brandName}/${refreshName}/${selectedTab}`);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  const breadcrumb = [
      ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{ label: 'Brand: No Brand Selected', link: null }]),
      ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand?.brand_name}/${selectedRefresh.refresh_name}` }] : [{ label: 'Refresh: No Refresh Selected', link: null }]),
      ...(selectedRefresh && selectedRefresh.selectedFile ? [{ label: `File: ${selectedRefresh.selectedFile.file.file_name}`, link: null }] : [{ label: 'File: No File Selected', link: null }])
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <AppBar position="static">
        <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Sales"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>leaderboard</Icon>}
            style={tabStyle(0)}
          />
          <Tab
            label="Marketing"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>stacked_line_chart</Icon>}
            style={tabStyle(1)}
          />
          <Tab
            label="Others"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>miscellaneous_services</Icon>}
            style={tabStyle(2)}
          />
          <Tab
            label="Data Log"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>view_list</Icon>}
            style={tabStyle(3)}
          />
        </Tabs>
             <BreadcrumbsComponent breadcrumb={breadcrumb}/>
      </AppBar>


      {tabValue === 0 && <SalesTab />}
      {tabValue === 1 && <MarketingTab />}
      {tabValue === 2 && <OthersTab />}
      {tabValue === 3 && <DataLogTab />}
      <Footer />
    </DashboardLayout>
  );
}

export default DataValidation;
