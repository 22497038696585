import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Plot from 'react-plotly.js';
import 'chart.js/auto';

const BarChart = ({ data }) => {
  const [chartData, setChartData] = useState(null);
  const [scatterData, setScatterData] = useState(null);
  const [chartHeight, setChartHeight] = useState(380);

  useEffect(() => {
    if (!data || !Array.isArray(data)) return;

    const variables = data.map(item => item.Variable || 'Unknown');
    const data_dict = {
      'Variable': variables,
      'Ad Spend': data.map(item => item['Ad Spend'] || 0),
      'Contribution': data.map(item => item['Contribution'] || 0),
      'ROI': data.map(item => item['ROI'] || 0),
      'MROI': data.map(item => item['MROI'] || 0),
    };

    // Adjust height dynamically based on the number of data points (rows)
    const calculatedHeight = Math.max(380, data.length * 35); // 35px per row, minimum of 380px
    setChartHeight(calculatedHeight);

    // Bar chart data
    const barChartData = {
      labels: data_dict.Variable,
      datasets: [
        {
          label: 'Ad Spend',
          data: data_dict['Ad Spend'],
          backgroundColor: 'skyblue',
        },
        {
          label: 'Contribution',
          data: data_dict['Contribution'],
          backgroundColor: 'lightgreen',
        },
        {
          label: 'ROI',
          data: data_dict['ROI'],
          backgroundColor: 'salmon',
        },
      ],
    };
    setChartData(barChartData);

    const scatterPlotData = {
      x: data_dict['ROI'],
      y: data_dict['MROI'],
      text: data_dict['Variable'],
      mode: 'markers+text',
      marker: { size: data_dict['Ad Spend'], color: 'rgba(0, 128, 255, 0.7)' },
    };

    setScatterData(scatterPlotData);
  }, [data]);

  if (!chartData || !scatterData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h3>Ad Spend, Contribution, and ROI by Variable</h3>

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ width: '30%', height: `${chartHeight}px` }}>
          <Bar
            data={{ labels: chartData.labels, datasets: [chartData.datasets[0]] }}
            options={{ indexAxis: 'y',
              scales: { x: { beginAtZero: true },},
              plugins: {
                title: { display: true, text: 'Ad Spend',  },
                legend: { display: false,},
              },
              maintainAspectRatio: false,
          }}
          />
        </div>
        <div style={{ width: '30%', height: `${chartHeight}px` }}>
            <Bar
                data={{
                  labels: chartData.labels,
                  datasets: [chartData.datasets[1]], // Contribution
                }}
                options={{
                  indexAxis: 'y',
                  scales: {
                    x: { beginAtZero: true },
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: 'Contribution',
                    },
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
              }}
          />
        </div>
        <div style={{ width: '30%', height: `${chartHeight}px`   }}>
          <Bar
            data={{
              labels: chartData.labels,
              datasets: [chartData.datasets[2]], // ROI
            }}
            options={{
              indexAxis: 'y',
              scales: {
                x: { beginAtZero: true },
              },
              plugins: {
                title: {
                  display: true,
                  text: 'ROI',
                },
                legend: {
                  display: false,
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>

{/*       <br /> */}
{/*       <h3>Labeled Scatter Plot (ROI vs MROI)</h3> */}
{/*       <Plot */}
{/*         data={[scatterData]} */}
{/*         layout={{ */}
{/*           title: 'Ad Spend, Contribution, and ROI by Variable', */}
{/*           height: 600, */}
{/*           width: 1000, */}
{/*           showlegend: false, */}
{/*           xaxis: { title: 'ROI' }, */}
{/*           yaxis: { title: 'MROI' }, */}
{/*         }} */}
{/*         config={{ */}
{/*           responsive: true, */}
{/*         }} */}
{/*       /> */}
    </div>
  );
};

export default BarChart;
