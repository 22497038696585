import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Card, Alert, Button, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress, IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import PyMC_png from 'assets/images/pymc.png';
import Robyn_png from 'assets/images/robyn.png';
import Lightweight_png from 'assets/images/lightweight.png';
import OLS_png from 'assets/images/ols.png';
import { useBrand } from 'layouts/utils/BrandContext';
import { useDataConfiguration } from 'hooks/useDataConfiguration';


const ModelingSetupTab = ( {setTabValue} ) => {
    const navigate = useNavigate();
    const { user_email, selectedBrand, selectedRefresh, setSelectedRefresh, configurationData, setConfigurationData, saveContext, loadContext } = useBrand();
    const { brandName, refreshName, tab } = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [currentModelType, setCurrentModelType] = useState(null);

    const [models, setModels] = useState(() => {
      const savedModels = localStorage.getItem('models');
      return savedModels ? JSON.parse(savedModels) : {
        pymc: { taskID: null, logs: [], completed: false, downloadLink: '' },
        robyn: { taskID: null, logs: [], completed: false, downloadLink: '' },
        lightweight: { taskID: null, logs: [], completed: false, downloadLink: '' },
        ols: { taskID: null, logs: [], completed: false, downloadLink: '' },
      };
    });

    const [isRunning, setIsRunning] = useState(() => {
      const savedIsRunning = localStorage.getItem('isRunning');
      return savedIsRunning ? JSON.parse(savedIsRunning) : {
        pymc: false,
        robyn: false,
        lightweight: false,
        ols: false,
      };
    });

    useEffect(() => {
      localStorage.setItem('models', JSON.stringify(models));
    }, [models]);

    useEffect(() => {
      localStorage.setItem('isRunning', JSON.stringify(isRunning));
    }, [isRunning]);

    const updateIsRunning = (modelType, isRunningValue) => {
        setIsRunning((prev) => {
            const updatedIsRunning = {
                ...prev,
                [modelType]: isRunningValue
            };
            localStorage.setItem('isRunning', JSON.stringify(updatedIsRunning));
            return updatedIsRunning;
        });
    };

    useEffect(() => {
        localStorage.setItem('isRunning', JSON.stringify(isRunning));
    }, [isRunning]);

    useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
    }, [brandName, refreshName, loadContext]);

    const {
        fetchDataConfigurationUserSelection,
        getUiConfigurationValues
    } = useDataConfiguration();

     const addLog = async (modelType, logMessage, timestamp) => {
        try {
            const queryParams = new URLSearchParams({
                user_email: user_email,
                brand_name: selectedBrand.brand_name,
                created_at: selectedRefresh.created_at,
                model_type: modelType,
                log_message: logMessage,
                timestamp: timestamp
            }).toString();
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const fullUrl = `${baseUrl}/model/add_log?${queryParams}`;
            await axios.post(fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
        } catch (error) {
            console.error("Error adding log:", error);
        }
    };

    const [taskIDs, setTaskIDs] = useState(() => {
        const savedTaskIDs = JSON.parse(localStorage.getItem('taskIDs'));
        return savedTaskIDs || {
            pymc: null,
            robyn: null,
            lightweight: null,
            ols: null,
        };
    });

    const updateTaskID = (modelType, taskID) => {
        setTaskIDs((prevTaskIDs) => {
            const updatedTaskIDs = {
                ...prevTaskIDs,
                [modelType]: taskID,
            };
            localStorage.setItem('taskIDs', JSON.stringify(updatedTaskIDs));
            return updatedTaskIDs;
        });
    };

    const [shouldConnect, setShouldConnect] = useState(() => {
        const savedShouldConnect = JSON.parse(localStorage.getItem('shouldConnect'));
        return savedShouldConnect || {
            pymc: false,
            robyn: false,
            lightweight: false,
            ols: false,
        };
    });

    const MAX_RECONNECT_ATTEMPTS = 3;
    const RECONNECT_DELAY = 5000; // 5 seconds between attempts

    const updateShouldConnect = (modelType, shouldConnectValue) => {
        console.log('updateShouldConnect', modelType, shouldConnectValue);
        setShouldConnect((prev) => {
            const updatedShouldConnect = {
                ...prev,
                [modelType]: shouldConnectValue
            };
            localStorage.setItem('shouldConnect', JSON.stringify(updatedShouldConnect));
            return updatedShouldConnect;
        });
    };

    const [reconnectAttempts, setReconnectAttempts] = useState(() => {
        const savedReconnectAttempts = JSON.parse(localStorage.getItem('reconnectAttempts'));
        return savedReconnectAttempts || {
            pymc: 0,
            robyn: 0,
            lightweight: 0,
            ols: 0,
        };
    });

    useEffect(() => {
        localStorage.setItem('reconnectAttempts', JSON.stringify(reconnectAttempts));
    }, [reconnectAttempts]);

    useEffect(() => {
        localStorage.setItem('models', JSON.stringify(models));
    }, [models]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const auth = useAuthInfo();

    const updateModel = (modelType, taskID) => {
        // Update taskIDs state
         updateTaskID(modelType, taskID);

        // Update models state and store it in localStorage
        setModels(prevModels => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    taskID: taskID,
                }
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });
    };

    const clearLogs = useCallback((modelType) => {
        console.log("Clearing logs for model type: ", modelType);
        setModels((prevModels) => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    logs: [],
                    completed: false,
                }
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });
    }, []);

    const updateLogs = useCallback((modelType, newLog) => {
        setModels(prevModels => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    logs: [...(prevModels[modelType]?.logs || []), newLog],
                }
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });
    }, []);

    const handleDataConfiguration = async () =>  {
        if (!selectedBrand || !selectedRefresh) {
            navigate(`/brand-manager`);
        } else {
            const configurationDataUserSelection = await fetchDataConfigurationUserSelection();
            getUiConfigurationValues(configurationDataUserSelection);
            navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-configuration`);
        };
    };

    const hasModelResultsForType = useCallback((modelType) => {
        if (!selectedRefresh?.Models_results) {
            return false;
        }
        return selectedRefresh.Models_results.some(file => file.model_type === modelType);
    }, [selectedRefresh]);

    const hasModelRunForType = useCallback((modelType) => {
        const prefix = "https://storage.cloud.google.com/uploaded_assets/";
        const downloadLink = models[modelType].downloadLink?.startsWith(prefix)
            ? models[modelType].downloadLink.slice(prefix.length)
            : models[modelType].downloadLink;

        if (downloadLink) {
            const pathParts = downloadLink.split('/');
            const extractedRefreshName = pathParts[pathParts.length - 2];

            // Return true if the refresh matches
            return extractedRefreshName === selectedRefresh?.refresh_name;
        }

        // Return false if no model run is found
        return false;
    }, [models, selectedRefresh]);

   useEffect(() => {
        const prefix = "https://storage.cloud.google.com/uploaded_assets/";

        Object.keys(models).forEach((modelType) => {
            const downloadLink = models[modelType].downloadLink?.startsWith(prefix)
                ? models[modelType].downloadLink.slice(prefix.length)
                : models[modelType].downloadLink;

            if (downloadLink) {
                const pathParts = downloadLink.split('/');
                const extractedRefreshName = pathParts[pathParts.length - 2];

                // Check if the refresh name matches and if the model results need to be updated
                if (extractedRefreshName === selectedRefresh?.refresh_name &&
                    !selectedRefresh?.Models_results?.some(file => file.filename === downloadLink && file.model_type === modelType)) {

                    // Update the selected refresh and save the context only if the data has changed
                    const updatedRefresh = {
                        ...selectedRefresh,
                        has_model_results: true,
                        Models_results: [
                            ...(selectedRefresh?.Models_results || []),
                            {
                                filename: downloadLink,
                                file_url: `https://storage.cloud.google.com/uploaded_assets/${downloadLink}`,
                                model_type: modelType
                            }
                        ]
                    };

                    setSelectedRefresh(updatedRefresh);

                    // Save the context after the refresh is updated
                    saveContext({
                        selectedBrand,
                        selectedRefresh: updatedRefresh,
                        configurationData,
                    });
                }
            }
        });
    }, [models, selectedRefresh, saveContext]);

    const hasUserSelectionChanged = useCallback((modelType) => {
        // Find the specific model's UserSelection in the selectedRefresh
        const selectedRefreshModel = selectedRefresh?.Models_results?.find(file => file.model_type === modelType);
        if (!selectedRefreshModel) {
            return false;
        }

        // Compare the entire UserSelection of selectedRefresh and configurationData
        const selectedRefreshUserSelection = selectedRefresh?.UserSelection;
        const configurationDataUserSelection = configurationData?.UserSelection;

        //console.log(`UserSelection in selectedRefresh for ${modelType}: `, JSON.stringify(selectedRefreshUserSelection));
        //console.log(`UserSelection in configurationData: `, JSON.stringify(configurationDataUserSelection));

        return JSON.stringify(selectedRefreshUserSelection) !== JSON.stringify(configurationDataUserSelection);
    }, [selectedRefresh, configurationData]);

    const backToModelResults = (modelType) => {
        setTabValue(1);
       // navigate(`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelsResults`);
    };

    const updateModelDownloadLink = (modelType, downloadLink) => {
        setModels(prevModels => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    downloadLink,
                    completed: true,
                },
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });
    };

    const updateDownloadLink = useCallback((modelType, downloadLink) => {
        updateModelDownloadLink(modelType, downloadLink)
        const updatedRefresh = {
            ...selectedRefresh,
            UserSelection: configurationData.UserSelection,
        };
        setSelectedRefresh(updatedRefresh);
        saveContext({
            selectedRefresh: updatedRefresh,
            configurationData,
        });

        setAlert({ show: true, message: `Model modeling for ${modelType} completed successfully. You can download the JSON outputs from the logs.`, color: 'success' });
        setOpenDialog(true);
        updateShouldConnect(modelType, false);
        updateIsRunning(modelType, false);
    }, [selectedRefresh, configurationData]);

    const showAlert = (message, color = "info", modelType = null) => {
        setAlert({ show: true, message, color });
        setCurrentModelType(modelType);
        setOpenDialog(true);
    };

    const wsRefs = useRef({ pymc: null, robyn: null, lightweight: null, ols: null });

    const reconnectWebSocket = (modelType) => {
        if (!models[modelType].completed && reconnectAttempts[modelType] < MAX_RECONNECT_ATTEMPTS) {
            setTimeout(() => {
                console.log("ModelingSetupTab>>index.jsx - Models: ", models);
                console.log("Reconnect Attempts: ", reconnectAttempts[modelType]);
                console.log(`Attempting to reconnect WebSocket for ${modelType}, attempt ${reconnectAttempts[modelType] + 1}`);
                setReconnectAttempts(prev => ({
                    ...prev,
                    [modelType]: prev[modelType] + 1,
                }));
                updateShouldConnect(modelType, true);
            }, RECONNECT_DELAY);
        } else {
            console.error(`Max reconnection attempts reached for ${modelType}. No further attempts will be made.`);
            updateIsRunning(modelType, false);
        }
    };

    useEffect(() => {
        const currentWsRefs = wsRefs.current;
        Object.keys(shouldConnect).forEach(modelType => {
            if (shouldConnect[modelType] && !currentWsRefs[modelType]) {
                const socketUrl = process.env.REACT_APP_WEB_SOCKET_SERVER;
                const socket = new WebSocket(socketUrl);

                socket.onopen = () => {
                    console.log(`WebSocket connection opened for ${modelType}`);
                    setReconnectAttempts(prev => ({
                        ...prev,
                        [modelType]: 0, // Reset on successful connection
                    }));
                    socket.send(JSON.stringify({ taskId: taskIDs[modelType], modelType }));
                    console.log("ModelingSetupTab>>index.jsx>>socket.onopen  - Models: ", models);
                    console.log(`Reconnect Attempts:  ${reconnectAttempts[modelType]} for model ${modelType}`);
                };

                socket.onmessage = (event) => {
                    const log = JSON.parse(event.data);
                    const timestamp = new Date().toLocaleString();
                    console.log("=================================================")
                    console.log(`log.model_type === modelType && log.task_id === taskIDs[modelType] ==> [${log.model_type} === ${modelType} && ${log.task_id} === ${taskIDs[modelType]}]`);
                    console.log(`${timestamp} socket.onmessage: ${log.message} - Type: ${log.model_type} - Task_id: ${log.task_id}`);
                    console.log("=================================================")

                    if (log.model_type === modelType && log.task_id === taskIDs[modelType]) {
                        if (log.message.includes("Download results")) {
                            const downloadLink = log.message.match(/Download results (.+)/)[1];
                            const linkHtml = `<a href="${downloadLink}" target="_blank" rel="noopener noreferrer" style="font-weight: bold; color: blue; padding-left: 10px;">JSON model outputs</a>`;
                            const log_message = `${timestamp} - Model ${modelType} modeling completed. You can download the model outputs here: ${linkHtml} (${timestamp})`;

                            updateLogs(modelType, log_message);
                            updateDownloadLink(modelType, downloadLink);
                            addLog(modelType, log.message, timestamp);
                            showAlert(`${modelType} modeling completed successfully!`, "info", modelType);
                            updateShouldConnect(modelType, false);
                            updateIsRunning(modelType, false);
                            socket.close();
                            currentWsRefs[modelType] = null;
                            console.log(`Download complete - WebSocket connection closed for ${modelType} task_id: ${taskIDs[modelType]}`);
                        }
                        if (log.message.includes("Error")) {
                            updateLogs(modelType, `${timestamp} - ${log.message}`);
                            addLog(modelType, `${timestamp} - ${log.message}`, timestamp);
                            updateShouldConnect(modelType, false);
                            updateIsRunning(modelType, false);

                             // Set the model as completed on error
                            setModels(prevModels => ({
                                ...prevModels,
                                [modelType]: {
                                    ...prevModels[modelType],
                                    completed: true,
                                }
                            }));

                            socket.close();
                            currentWsRefs[modelType] = null;
                            console.log(`log error - WebSocket connection closed for ${modelType} task_id: ${taskIDs[modelType]}`);
                            showAlert(log.message, "error", modelType);
                        }
                        if (log.message.includes("Warning")) {
                            updateLogs(modelType, `${timestamp} - ${log.message}`);
                            addLog(modelType, `${timestamp} - ${log.message}`, timestamp);
                            showAlert(log.message, "warning", modelType);
                        } else {
                            updateLogs(log.model_type, log.message);
                            addLog(modelType, `${timestamp} - ${log.message})`, timestamp);
                        }
                    }
                };
                socket.onerror = (error) => {
                    console.error(`WebSocket error for ${modelType}:`, error);
                };
                socket.onclose = () => {
                    console.log(`socket.onclose - WebSocket connection closed for ${modelType}`);
                    currentWsRefs[modelType] = null;

                    // Check if we should reconnect
                    setTimeout(() => {
                        if (shouldConnect[modelType]) {
                            reconnectWebSocket(modelType);  // Attempt to reconnect
                        }
                    }, 0); // Ensure this check is done after state update
                };
                currentWsRefs[modelType] = socket;
            }
        });
        // Clean up WebSocket connections on component unmount
        return () => {
            Object.values(currentWsRefs).forEach(socket => {
                if (socket) {
                    socket.close();
                }
            });
        };
    }, [shouldConnect, taskIDs]); // Run effect when shouldConnect or taskIDs change

    const runModel = async (modelType) => {
        const taskID = uuidv4();
        updateIsRunning(modelType, true);

        const queryParams = new URLSearchParams({
            file_name: selectedRefresh.selectedFile.file.file_url,
            brand: selectedBrand.brand_name,
            refresh_name: selectedRefresh.refresh_name,
            user_selection: JSON.stringify(configurationData.UserSelection),
            task_id: taskID,
            model_type: modelType
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/model/start_modeling?${queryParams}`;
        try {
            clearLogs(modelType);
            updateShouldConnect(modelType, true);
            updateModel(modelType, taskID);

            const timestamp = new Date().toLocaleString();
            updateLogs(modelType, `${timestamp} - Starting modeling...`);
            addLog(modelType, `${timestamp} - Starting modeling...`, timestamp);

            const response = await axios.post(
                fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
            if (response.status === 200) {
                const log_message = `${timestamp} - Modeling for ${modelType} for brand ${selectedBrand.brand_name} has started.\nTask id: ${taskID} - Model type: ${modelType}`;
                updateLogs(modelType, log_message);
                addLog(modelType, log_message, timestamp);
            } else {
                showAlert(`Error running model: ${response.statusText || 'Unknown error'}`, 'error', modelType);
                updateShouldConnect(modelType, false);
                updateIsRunning(modelType, false);
            }

        } catch (error) {
            let errorMessage = 'Error running model';
            if (error.response && error.response.data && error.response.data.detail) {
                errorMessage = `Error running model: ${error.response.data.detail}`;
            } else if (error.message) {
                errorMessage = `Error running model: ${error.message}`;
            }
            showAlert(errorMessage, 'error', modelType);
            updateShouldConnect(modelType, false);
            updateIsRunning(modelType, false);
        }
    };

    return (
        <>
            <Dialog open={openDialog} aria-labelledby="Modeling" aria-describedby="run-models" onClose={handleCloseDialog}>
                <DialogTitle>Modeling Status for {currentModelType}</DialogTitle>
                <DialogContent>
                    {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {loading ? (
                <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress sx={{ color: blue[500] }} />
                    <MDTypography variant="normal" sx={{ mt: 2 }}>
                        Loading data configuration for <strong>{selectedRefresh?.refresh_name}</strong>...
                    </MDTypography>
                </MDBox>
            ) : (
           <Card sx={{  mb: '15px' }}>
           <MDBox sx={{ width: { xs: '100%', sm: '70%', md: '60%' }, margin: '0 auto', p: { xs: 2, sm: 4 }}}>
                <MDTypography component="div" sx={{ mt: 2, width: '100%', textAlign: 'center' }} variant="body2">
                    Select Model to Run for <strong>{selectedRefresh?.selectedFile?.file.file_name}</strong>
                </MDTypography>
                <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={Robyn_png} alt="Robyn" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">Robyn</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Run Model">
                                <span>
                                    <IconButton
                                        onClick={() => runModel('robyn')}
                                        sx={{ color: hasUserSelectionChanged('robyn') || !selectedRefresh?.selectedFile || hasModelRunForType('robyn') ? 'lightgrey' : '#006400' }}
                                        disabled={hasUserSelectionChanged('robyn') || !selectedRefresh?.selectedFile || hasModelRunForType('robyn')}
                                    >
                                        <PlayArrowIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {isRunning.robyn && (
                                <CircularProgress size={18} sx={{  color: blue[500], margin: '0 10px' }} />
                            )}
                            <Tooltip title="Last Run Results">
                                    <span>
                                        <IconButton
                                            disabled={!hasModelResultsForType('robyn')}
                                            onClick={() => backToModelResults('robyn')}
                                            sx={{ color: hasModelResultsForType('robyn') ? 'blue' : 'lightgrey' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </span>
                            </Tooltip>
                        </MDBox>
                    </MDBox>
                    {models['robyn'] && models['robyn'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['robyn'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                <MDBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', margin: '20px 0' }}>
                    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={PyMC_png} alt="PyMC" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">PyMC</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Run Model">
                                <span>
                                    <IconButton
                                        onClick={() => runModel('pymc')}
                                        sx={{ color: hasUserSelectionChanged('pymc') || !selectedRefresh?.selectedFile || hasModelRunForType('pymc') ? 'lightgrey' : '#006400' }}
                                        disabled={hasUserSelectionChanged('pymc') || !selectedRefresh?.selectedFile || hasModelRunForType('pymc')}
                                    >
                                        <PlayArrowIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {isRunning.pymc && (
                                <CircularProgress size={18} sx={{  color: blue[500], margin: '0 10px' }} />
                            )}
                            <Tooltip title="Last Run Results">
                                    <span>
                                        <IconButton
                                            disabled={!hasModelResultsForType('pymc')}
                                            onClick={() => backToModelResults('pymc')}
                                            sx={{ color: hasModelResultsForType('pymc') ? 'blue' : 'lightgrey' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </span>
                            </Tooltip>
                        </MDBox>
                    </MDBox>
                    {models['pymc'] && models['pymc'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['pymc'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={OLS_png} alt="OLS" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">OLS</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Run Model">
                                <span>
                                    <IconButton
                                        onClick={() => runModel('ols')}
                                        sx={{ color: hasUserSelectionChanged('ols') || !selectedRefresh?.selectedFile || hasModelRunForType('ols') ? 'lightgrey' : '#006400' }}
                                        disabled={hasUserSelectionChanged('ols') || !selectedRefresh?.selectedFile || hasModelRunForType('ols')}
                                    >
                                        <PlayArrowIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {isRunning.ols && (
                                <CircularProgress size={18} sx={{  color: blue[500], margin: '0 10px' }} />
                            )}
                            <Tooltip title="Last Run Results">
                                    <span>
                                        <IconButton
                                            disabled={!hasModelResultsForType('ols')}
                                            onClick={() => backToModelResults('ols')}
                                            sx={{ color: hasModelResultsForType('ols') ? 'blue' : 'lightgrey' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </span>
                            </Tooltip>
                        </MDBox>
                    </MDBox>
                    {models['ols'] && models['ols'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['ols'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                </MDBox>
                    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={Lightweight_png} alt="Lightweight" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">Lightweight</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Run Model">
                                <span>
                                    <IconButton
                                        onClick={() => runModel('lightweight')}
                                        sx={{ color: hasUserSelectionChanged('lightweight') || !selectedRefresh?.selectedFile || hasModelRunForType('lightweight') ? 'lightgrey' : '#006400' }}
                                        disabled={hasUserSelectionChanged('lightweight') || !selectedRefresh?.selectedFile || hasModelRunForType('lightweight')}
                                    >
                                        <PlayArrowIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {isRunning.lightweight && (
                                <CircularProgress size={18} sx={{  color: blue[500], margin: '0 10px' }} />
                            )}
                            <Tooltip title="Last Run Results">
                                    <span>
                                        <IconButton
                                            disabled={!hasModelResultsForType('lightweight')}
                                            onClick={() => backToModelResults('lightweight')}
                                            sx={{ color: hasModelResultsForType('lightweight') ? 'blue' : 'lightgrey' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </span>
                            </Tooltip>
                        </MDBox>
                    </MDBox>
                    {models['lightweight'] && models['lightweight'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['lightweight'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                <MDTypography component="div" sx={{ mb: 2, width: '100%', textAlign: 'center' }} variant="h4">
                    <MDButton
                        variant="contained"
                        disabled={!selectedBrand || !selectedRefresh || !selectedRefresh.selectedFile}
                        color="dark"
                        onClick={handleDataConfiguration}
                        sx={{ alignSelf: 'center', mb: '5px' }}
                    >
                        Data Configuration
                    </MDButton>
                </MDTypography>
            </MDBox>
          </Card>
            )}
        </>
    );
};

export default ModelingSetupTab;
