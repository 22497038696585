import { useEffect, useState, useCallback } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import brandWhite from "assets/images/mmmlabs-logo.png";
import Sidenav from "assets/Sidenav";
import theme from "assets/theme";
import Home from "layouts/home/Home";
import BrandManager from "layouts/brand-manager/BrandManager";
import DataValidation from "layouts/data-validation/DataValidation";
import DataHub from "layouts/data-hub/DataHub";
import AIInsights from "layouts/ai-insights/AIInsights";
import ScenarioPlanner from "layouts/scenario-planner/ScenarioPlanner";
import ScenarioDetails from "layouts/scenario-planner/components/ScenarioDetails";
import Modeling from "layouts/modeling/Modeling";
import InsightDeckBuilder from "layouts/insight-deck-builder/InsightDeckBuilder";
import RoutesAdmin from "RoutesAdmin";
import RoutesMember from "RoutesMember";
import RoutesTrial from "RoutesTrial";
import { useMaterialUIController } from "context";
import { useAuthInfo, withAuthInfo, useRedirectFunctions } from "@propelauth/react";
import { BrandProvider, useBrand } from 'layouts/utils/BrandContext';
import SignUp from "layouts/registration/SignUp";

const App = withAuthInfo((props) => {
    const authInfo = useAuthInfo();
    const { redirectToLoginPage } = useRedirectFunctions();
    const [controller] = useMaterialUIController();
    const { layout } = controller;

    // Access brand and refresh selection from the context
    const { selectedBrand, selectedRefresh } = useBrand();

    // Use a state to store routes so that it can be updated dynamically
    const [routes, setRoutes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
    }, []);

    // Function to determine and set the routes dynamically
    const determineRoutes = useCallback((userRole) => {
        if (userRole === "Trial") {
            setRoutes(RoutesTrial({ selectedBrand, selectedRefresh }));
        } else if (userRole === "Admin" || userRole === "Owner") {
            setRoutes(RoutesAdmin({ selectedBrand, selectedRefresh }));
        } else {
            setRoutes(RoutesMember({ selectedBrand, selectedRefresh }));
        }
    }, [selectedBrand, selectedRefresh]);

    // Effect to update routes when brand or refresh changes
    useEffect(() => {
       if (props.isLoggedIn) {
            const userRole = authInfo.orgHelper.getOrgs()[0].userAssignedRole;
            determineRoutes(userRole);
       }
    }, [props.isLoggedIn, determineRoutes]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }
            return null;
        });

    // Component for navigation buttons
    const NavigationButtons = () => {
        const navigate = useNavigate();
        return (
            <>
                <button onClick={() => redirectToLoginPage()}>Login</button>
                <button onClick={() => navigate("/signup")}>SignUp</button>
            </>
        );
    };

    if (props.isLoggedIn) {
        return (
            <BrandProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {layout === "brand-manager" && (
                        <Sidenav
                            key={`${selectedBrand?.brand_name}-${selectedRefresh?.refresh_name}`}
                            props={props}
                            brand={brandWhite}
                            brandName="Data Explorer"
                            routes={routes}
                        />
                    )}
                    <Routes>
                        {getRoutes(routes)}
                        <Route path="/home/:brandName" element={<Home />} />
                        <Route path="/brand-manager/*" element={<BrandManager />} />
                        <Route path="/data-hub/:brandName/:refreshName/:tab" element={<DataHub />} />
                        <Route path="/data-validation/:brandName/:refreshName/:tab" element={<DataValidation />} />
                        <Route path="/modeling/:brandName/:refreshName/:tab" element={<Modeling />} />
                        <Route path="/ai-insights/:brandName/:refreshName/:tab" element={<AIInsights />} />
                        <Route path="/scenario-planner/:brandName/:refreshName" element={<ScenarioPlanner />} />
                        <Route path="/scenarios/:brandName" element={<ScenarioDetails />} />
                        <Route path="/insight-deck-builder/:brandName/:refreshName/:tab" element={<InsightDeckBuilder />} />
                    </Routes>
                </ThemeProvider>
            </BrandProvider>
        );
    } else {
        return (
            <div>
                <div className="container">
                    <div className="centered-box">
                        <div className="user-section-logged-off">
                            <a href="https://mmmlabs.ai">
                                <img src={brandWhite} alt="MMM Labs" className="logo-landing-page" />
                            </a>
                            <div className="logoff-text">You are not logged in</div>
                            <NavigationButtons />
                        </div>
                        <div className="logoff-text">
                            Start your MMM by uploading your sales & marketing data
                        </div>
                    </div>
                </div>
                <Routes>
                    <Route path="/signup" element={<SignUp />} />
                </Routes>
            </div>
        );
    }
});

export default App;
