import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Card, CircularProgress, Icon, Avatar, TextField } from '@mui/material';
import { blue } from '@mui/material/colors';
import mmmlabsIcon from 'assets/images/mmmlabs-icon.png';
import { useParams } from 'react-router-dom';
import { useBrand } from 'layouts/utils/BrandContext';

const Chat = () => {
  const [customPrompt, setCustomPrompt] = useState('');
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user_first_last_name, user_email, user_company, selectedBrand } = useBrand();
  const { brandName } = useParams();

  const predefinedPrompts = [
    "How do I interpret the results of my marketing mix model?",
    "Can you guide me through MMM Labs's interface?",
    "What does this marketing data indicate about media performance?",
    "How can I optimize my marketing mix based on these results?"
  ];

  // Fetch chat history when component mounts
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/ai/chat/history`;
        const result = await axios.post(fullUrl, { email: user_email, company: user_company, brand_name: brandName });
        setHistory(result.data.history || []);
      } catch (error) {
        console.error("Failed to load chat history:", error);
      }
    };
    if (brandName) {
        fetchHistory();
    } else {
        console.log("No brand name provided. Skipping history fetch.");
    }
  }, [user_email, user_company, brandName]);

  // Function to send a user prompt
  const sendPrompt = async (prompt, ai_model = "gpt-3.5-turbo") => {
    setLoading(true);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/ai/chat/`;
    try {
      const result = await axios.post(fullUrl, { prompt: prompt, ai_model: ai_model, email: user_email, company: user_company, brand_name: brandName},
      {
        headers: { 'Content-Type': 'application/json' }
      });
      setHistory(result.data.history || []);
    } catch (error) {
      console.error("Failed to send prompt:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to clear the chat history
  const clearHistory = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/ai/chat/clear-history`;
    try {
      await axios.post(fullUrl, { email: user_email, company: user_company, brand_name: brandName });
      setHistory([]); // Clear history from the UI
    } catch (error) {
      console.error("Failed to clear history:", error);
    }
  };

  const handleCustomPromptSubmit = () => {
    if (customPrompt.trim()) {
      sendPrompt(customPrompt);
      setCustomPrompt(''); // Clear the input after submission
    }
  };

  // Function to format the response with proper formatting like bold and bullet points
  const formatResponse = (response) => {
    if (!response) return ''; // Return empty string if response is undefined or null

    // Replace **bold** with <strong>bold</strong>
    let formatted = response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Split into lines
    const lines = formatted.split('\n');

    let html = '';
    let listStarted = false;

    lines.forEach((line) => {
      const trimmedLine = line.trim();

      if (/^[-•]\s/.test(trimmedLine)) {
        if (!listStarted) {
          html += '<ul>';
          listStarted = true;
        }
        // Remove the bullet and add as <li>
        html += `<li>${trimmedLine.replace(/^[-•]\s*/, '')}</li>`;
      } else {
        if (listStarted) {
          html += '</ul>';
          listStarted = false;
        }
        if (trimmedLine) {
          html += `<p>${trimmedLine}</p>`;
        }
      }
    });

    if (listStarted) {
      html += '</ul>';
    }

    return html;
  };

  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="40vh"
      sx={{ backgroundColor: '#f4f6f8', mb: 2, mt: 2 }}
    >
      <Card sx={{ p: 4, width: '100%', maxWidth: 900 }}>
        <MDTypography variant="h4" sx={{ mb: 3 }} textAlign="center">
          Welcome {user_first_last_name}!
        </MDTypography>

        {!brandName && (
          <MDTypography variant="normal" sx={{ color: 'red', mb: 2 }}>
            Please select a brand before using the MMM Labs AI Agent.
          </MDTypography>
        )}

        {/* Predefined prompts */}
        <MDBox
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 2,
            mb: 3
          }}
        >
          {predefinedPrompts.map((text, index) => (
            <MDButton
              key={index}
              variant="contained"
              onClick={() => sendPrompt(text)}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                backgroundColor: 'white',
                color: 'black',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                flex: '1 1 22%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#f0f0f0'
                }
              }}
            >
              <MDTypography variant="normal">{text}</MDTypography>
              <Icon>arrow_forward_ios</Icon>
            </MDButton>
          ))}
        </MDBox>

        {/* Chat History */}
        {history.length > 0 && history.map((entry, index) => (
          entry.role !== 'system' && ( // Ignore entries with the role 'system'
            <React.Fragment key={index}>
              {/* User's Message */}
              {entry.role === "user" && (
                <MDBox display="flex" alignItems="flex-start"   sx={{mt: 3, p: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fafafa', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Icon sx={{ color: blue[500], mr: 1, mt: 0.15 }}>person</Icon>
                  <MDTypography variant="normal">
                    <strong>{user_first_last_name}:</strong> {entry.content}
                  </MDTypography>
                </MDBox>
              )}

              {/* Assistant's Response */}
              {entry.role === "assistant" && (
                <MDBox display="flex" alignItems="flex-start" sx={{mt: 3, p: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fafafa', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Avatar src={mmmlabsIcon} sx={{ width: 24, height: 24, mr: 1 }} />
                  <MDTypography variant="normal">
                    <strong>MMM Labs AI:</strong> <span dangerouslySetInnerHTML={{ __html: formatResponse(entry.content) }} />
                  </MDTypography>
                </MDBox>
              )}

              {/* Handle the user/assistant structure from API */}
              {entry.user && (
                <MDBox display="flex" alignItems="flex-start" sx={{mt: 3, p: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fafafa', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Icon sx={{ color: blue[500], mr: 1, mt: 0.15 }}>person</Icon>
                  <MDTypography variant="normal">
                    <strong>{user_first_last_name}:</strong> {entry.user}
                  </MDTypography>
                </MDBox>
              )}

              {entry.assistant && (
                <MDBox display="flex" alignItems="flex-start" sx={{mt: 3, p: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fafafa', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Avatar src={mmmlabsIcon} sx={{ width: 24, height: 24, mr: 1 }} />
                  <MDTypography variant="normal">
                    <strong>MMM Labs AI:</strong> <span dangerouslySetInnerHTML={{ __html: formatResponse(entry.assistant) }} />
                  </MDTypography>
                </MDBox>
              )}
            </React.Fragment>
          )
        ))}

        {loading && (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: '100px' }}
          >
            <CircularProgress sx={{ color: blue[500] }} />
          </MDBox>
        )}

        <MDBox
          display="flex"
          alignItems="center"
          sx={{
            mt: 3,
            p: 2,
            border: '1px solid #ddd',
            borderRadius: 2,
            backgroundColor: '#fafafa',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
        >
          <TextField
            label="Ask a question or give a prompt"
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleCustomPromptSubmit();
              }
            }}
            fullWidth
            variant="outlined"
            sx={{ mr: 2 }}
          />
          <MDButton
            variant="contained"
            color="dark"
            onClick={handleCustomPromptSubmit}
            disabled={!brandName || !customPrompt.trim()}
          >
            Send
          </MDButton>
        </MDBox>

        <MDBox mt={2} display="flex" justifyContent="center">
          <MDButton variant="outlined" color="info" onClick={clearHistory} disabled={!brandName}>
            Clear History
          </MDButton>
        </MDBox>

        <MDTypography variant="caption" sx={{ mt: 2, color: 'gray', fontSize: '0.8rem' }}>
          MMM Labs AI Agent is experimental and may provide inaccurate results. Chats are not shared with any third parties.
        </MDTypography>
      </Card>
    </MDBox>
  );
};

export default Chat;
