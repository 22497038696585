import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {  IconButton, Menu, MenuItem, Modal, Card, CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import RefreshDetails from 'layouts/brand-manager/components/RefreshDetails';
import { useBrand } from 'layouts/utils/BrandContext';


const DataConnectionTab = React.memo(({ refresh }) => {
    const navigate = useNavigate();
    const { selectedBrand, selectedRefresh } = useBrand();

    return (
        <MDBox p={2} pb={2} pr={5}>
          <MDTypography variant="h3">Data Connection</MDTypography>
            <MDBox p={3} pb={3} pr={5}>
              < RefreshDetails />
            </MDBox>
        </MDBox>
        );
});

export default DataConnectionTab;
