import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import 'assets/css/table.css';
import { useBrand } from 'layouts/utils/BrandContext';


const DataLogTab = () => {
    const { loadContext, selectedBrand, selectedRefresh } = useBrand();
    const { brandName, refreshName, fileName } = useParams();

    useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
    }, [brandName, refreshName, loadContext]);

    const [data, setData] = useState(() => {
        const savedData = localStorage.getItem('dataLog');
        return savedData ? JSON.parse(savedData) : null;
    });

    // Update localStorage when data changes
    useEffect(() => {
        localStorage.setItem('dataLog', JSON.stringify(data));
    }, [data]);

    return (
      <>
        <MDBox>
            <MDTypography variant="h3" >
                Data Log
            </MDTypography>
        </MDBox>
        <br/>
      <Card>
          <MDBox pt={3} px={2} height="300px">
            <MDTypography variant="normal" fontWeight="normal">
                Coming soon...
            </MDTypography>
          </MDBox>
       </Card>
      <br />
    </>
    );
};

export default DataLogTab;
