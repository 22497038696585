import React, { useState, useEffect, useContext } from 'react';
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios';
import { Card, Alert, Button, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { blue } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useBrand } from 'layouts/utils/BrandContext';
import BrandItem from "./BrandItem";
import AddBrandDialog from "./AddBrandDialog";
import { useDataConfiguration } from 'hooks/useDataConfiguration';

const Brands = () => {
  const { setSelectedBrand, selectedBrand, selectedRefresh, saveContext, configurationData, loadContext } = useBrand();
  const [open, setOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useAuthInfo();
  const navigate = useNavigate();

  const { fetchDataConfigurationUserSelection } = useDataConfiguration();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCreateBrand = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showAlert = (message, color = "info") => {
    setAlert({ show: true, message, color });
    setOpenDialog(true);
  };

  const handleSuccess = (brand) => {
    setBrands([...brands, brand]);
    setAlert({ show: true, message: 'Brand created successfully!', color: 'success' });
    setOpenDialog(true);
  };

  const handleBrandClick = async (brand) => {
    setSelectedBrand(brand);
    loadContext(brand.brand_name);
    localStorage.clear();  // Clear all storage when selecting a new brand.
    // Check if configurationData is available and get it otherwise
    if (!brand && (!configurationData || Object.values(configurationData.UserSelection || {}).some(field => field === ""))) {
        fetchDataConfigurationUserSelection();
   }
    navigate(`/brand-manager/${brand.brand_name}`);
  };

  const handleUpdateBrand = async (old_brand_name, updatedBrand, file_logo) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('old_brand_name', old_brand_name);
    formData.append('brand_name', updatedBrand.brand_name);
    formData.append('sub_name', updatedBrand.sub_name);
    formData.append('industry', updatedBrand.industry);
    formData.append('logo_url', updatedBrand.logo_url);
    if (file_logo) {
      formData.append('file_logo', file_logo);
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/brands/update_brand`;
    try {
      const response = await axios.post(fullUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${auth.accessToken}`
        }
      });

      if (response.data) {
        const newLogoUrl = response.data.logo_url;
        const updatedBrands = brands.map(b =>
          b.brand_name === old_brand_name ? { ...updatedBrand, logo_url: newLogoUrl || b.logo_url } : b
        );
        setBrands(updatedBrands);
        setAlert({ show: true, message: response.data.message, color: 'success' });
        setOpenDialog(true);
        const contextData = {
          selectedBrand: updatedBrands,
          selectedRefresh: null,
          configurationData: null
        };
        saveContext(contextData);
      }
    } catch (error) {
      console.error('Error updating brand:', error);
      setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBrand = async (brand_name) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('brand_name', brand_name);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/brands/delete_brand`;

    try {
      const response = await axios.post(fullUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${auth.accessToken}`
        }
      });
      if (response.data) {
        const updatedBrands = brands.filter(b => b.brand_name !== brand_name);
        setBrands(updatedBrands);
        setAlert({ show: true, message: response.data.message, color: 'success' });
        setOpenDialog(true);
        const contextData = {
          selectedBrand: null,
          selectedRefresh: null,
          configurationData: null
        };
        saveContext(contextData);
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
      setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchBrands = async () => {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const fullUrl = `${baseUrl}/brands/get_brands`;
      try {
        const response = await axios.get(fullUrl, {
          headers: {
            "Authorization": `Bearer ${auth.accessToken}`
          }
        });
        setBrands(response.data.brands);
      } catch (error) {
        console.error('Error fetching brands:', error);
        setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
        setOpenDialog(true);
      }
    };
    fetchBrands();
  }, [auth.accessToken]);

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <br/>
      {loading ? (
        <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress sx={{ color: blue[500] }} />
          <MDTypography variant="body2" sx={{ mt: 2 }}>Loading brands...</MDTypography>
        </MDBox>
      ) : (
        <Card>
          <MDBox p={2}>
            <MDBox sx={{ display: 'flex', flexWrap: 'wrap', mt: 3 }}>
              {brands.map((brand) => (
                <BrandItem
                  key={brand.brand_name}
                  brand={brand}
                  onClick={() => handleBrandClick(brand)}
                  onUpdate={handleUpdateBrand}
                  onDelete={handleDeleteBrand}
                />
              ))}
              <Card
                sx={{
                  width: 130,
                  m: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  }
                }}
              >
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    cursor: 'pointer',
                    padding: 2,
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    textAlign: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                    }
                  }}
                  onClick={handleCreateBrand}
                >
                  <AddIcon fontSize="small" />
                  <MDTypography variant="h6" sx={{ mt: 1 }}>New Brand</MDTypography>
                </MDBox>
              </Card>
            </MDBox>
          </MDBox>
        </Card>
      )}
      <AddBrandDialog open={open} onClose={handleClose} onSuccess={handleSuccess} />
    </>
  );
};

export default Brands;
