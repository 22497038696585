import { useEffect } from "react";
import logo from 'assets/images/mmmlabs-logo.png';
import 'assets/css/app.css';
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "assets/Sidenav/SidenavCollapse";
import SidenavRoot from "assets/Sidenav/SidenavRoot";
import { useBrand } from 'layouts/utils/BrandContext';
import { useAuthInfo } from "@propelauth/react";
import { useMaterialUIController, setMiniSidenav } from "context";
import getAccountUrl from "layouts/utils/Config";

const accountUrl = getAccountUrl();

function Sidenav({ color = 'info', brand = "", brandName, routes, props = { user: { email: "" } }, ...rest }) {
  const authInfo = useAuthInfo();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const { setUserEmail } = useBrand();
  const { setUserCompany } = useBrand();
  const { setUserFirstLastName } = useBrand();
  const { setUserRole } = useBrand();
  const auth = useAuthInfo();
  const orgId = Object.keys(auth.userClass.orgIdToUserOrgInfo)[0];
  const org = (auth.userClass.orgIdToUserOrgInfo[orgId]).orgName;
  const userRole = authInfo.orgHelper.getOrgs()[0].userAssignedRole;
  const { selectedBrand, selectedRefresh } = useBrand();
  let textColor = "dark";

  useEffect(() => {
    // Set the user email and company in the BrandContext
    setUserEmail(props.user.email);
    setUserCompany(org);
    setUserFirstLastName(props.user.firstName + " " + props.user.lastName);
    setUserRole(userRole);

    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, setUserEmail, setUserCompany, org, props.user.email]);

   // Dynamically generate routes based on selectedBrand and selectedRefresh
  const dynamicRoutes = [
    {
      key: "home",
      type: "collapse",
      name: "Home",
      icon: <Icon fontSize="small">home</Icon>,
      route: selectedBrand
        ? `/home/${selectedBrand.brand_name}`
        : "/home",
    },
    {
      key: "brand-manager",
      type: "collapse",
      name: "Brand Manager",
      icon: <Icon fontSize="small">dashboard_customize</Icon>,
      route: "/brand-manager",
    },
     {
      key: "data-hub",
      type: "collapse",
      name: "Data Hub",
      icon: <Icon fontSize="small">device_hub</Icon>,
      route: selectedBrand && selectedRefresh
        ? `/data-hub/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/dataReadiness`
        : "/data-hub",
    },
    {
      key: "data-validation",
      type: "collapse",
      name: "Data Validation",
      icon: <Icon fontSize="small">fact_check</Icon>,
      route: selectedBrand && selectedRefresh
        ? `/data-validation/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/sales`
        : "/data-validation",
    },
    {
      key: "modeling",
      type: "collapse",
      name: "Modeling",
      icon: <Icon fontSize="small">dataset</Icon>,
      route: selectedBrand && selectedRefresh
        ? `/modeling/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/modelingSetup`
        : "/modeling",
    },
    {
      key: "ai-insights",
      type: "collapse",
      name: "AI Insights",
      icon: <Icon fontSize="small">insights</Icon>,
      route: selectedBrand && selectedRefresh
        ? `/ai-insights/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/exec`
        : "/ai-insights",
    },
    {
      key: "scenario-planner",
      type: "collapse",
      name: "Scenario Planner",
      icon: <Icon fontSize="small">business_center</Icon>,
      route: selectedBrand && selectedRefresh
        ? `/scenario-planner/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}`
        : "/scenario-planner",
    },
  ];

  // Conditionally add the "Insight Deck Builder " route if userRole is not "Trial"
  if (userRole !== "Trial") {
    dynamicRoutes.push({
      key: "insight-deck-builder",
      type: "collapse",
      name: "Insight Deck Builder",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: selectedBrand && selectedRefresh
        ? `/insight-deck-builder/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/deckBuilder`
        : "/insight-deck-builder",
    });
  }

  // Add the "Account" and "Log Out" routes
  dynamicRoutes.push(
    {
      key: "account",
      type: "collapse",
      name: "Account",
      icon: <Icon fontSize="small">person</Icon>,
      route: accountUrl + '/account',
    },
    {
      key: "logout",
      type: "collapse",
      name: "Log Out",
      icon: <Icon fontSize="small">logout</Icon>,
      route: "/authentication/logout",
    }
  );

  // Render routes dynamically
  const renderRoutes = dynamicRoutes.map(({ type, name, icon, key, href, route }) => {
    if (type === "collapse") {
      // Check if the current location path starts with the static part of the route
      const isActive = location.pathname.startsWith(route.split("/").slice(0, 2).join("/"));
      return href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={isActive}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse
            name={name}
            icon={icon}
            active={isActive}
          />
        </NavLink>
      );
    }
    return null;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
             <img src={logo} alt="MMM Labs" className="logo" />
          {brand && <MDBox width="2rem" />}
        </MDBox>
          <MDTypography fontSize="1.4rem" fontWeight="medium" color={textColor} >
             {brandName}
          </MDTypography>
      </MDBox>
      <Divider
        light={true}
      />
      <List>{renderRoutes}</List>
      <Divider
        light={false}
      />
      <MDTypography  variant="caption" textAlign="center">
            <b>{props.user.firstName} {props.user.lastName}</b>
            <br/> is logged in with username<br/>
            <b>{props.user.email}</b>
            <br/>from<br/>
            <b>{org}</b>
       </MDTypography>
    </SidenavRoot>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
