import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import { useBrand } from 'layouts/utils/BrandContext';
import Footer from "assets/Footer";

import Brands from './components/Brands';
import BrandDetails from './components/BrandDetails';
import RefreshDetails from './components/RefreshDetails';
import DataConfiguration from 'layouts/data-configuration/components/DataConfiguration';

function BrandManager() {
    const { selectedBrand, selectedRefresh } = useBrand();
    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    };
    const tabStyle = (index) => ({
        border: tabValue === index ? '2px solid #247EEA' : 'none'
    });

    const breadcrumb = [
      ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{ label: 'Brand: No Brand Selected', link: null }]),
      ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand?.brand_name}/${selectedRefresh.refresh_name}` }] : [{ label: 'Refresh: No Refresh Selected', link: null }]),
      ...(selectedRefresh && selectedRefresh.selectedFile ? [{ label: `File: ${selectedRefresh.selectedFile.file.file_name}`, link: null }] : [{ label: 'File: No File Selected', link: null }])
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                    <Tab
                        label="Brands Setup"
                        icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>dashboard_customize</Icon>}
                        style={tabStyle(0)}
                    />
                </Tabs>
              <BreadcrumbsComponent breadcrumb={breadcrumb}/>
            </AppBar>
            {tabValue === 0 && (
                <Routes>
                    <Route path="/" element={<Brands />} />
                    <Route path="/:brandName" element={<BrandDetails />} />
                    <Route path="/:brandName/:refreshName" element={<RefreshDetails />} />
                    <Route path="/:brandName/:refreshName/data-configuration" element={<DataConfiguration />} />
                </Routes>
            )}
            <br/>
            <Footer />
        </DashboardLayout>
    );
}

export default BrandManager;
