import Home from "layouts/home/Home";
import BrandManager from "layouts/brand-manager/BrandManager";
import DataValidation from "layouts/data-validation/DataValidation";
import DataHub from "layouts/data-hub/DataHub";
import AIInsights from "layouts/ai-insights/AIInsights";
import Modeling from "layouts/modeling/Modeling";
import ScenarioPlanner from "layouts/scenario-planner/ScenarioPlanner";
import LogOut from "layouts/authentication/logout";
import Icon from "@mui/material/Icon";
import getAccountUrl from "layouts/utils/Config";

const accountUrl = getAccountUrl();

const RoutesTrial = ({ selectedBrand, selectedRefresh }) => {
    const routes = [
     {
        key: "home",
        type: "collapse",
        name: "Home",
        icon: <Icon fontSize="small">home</Icon>,
        route: selectedBrand
            ? `/home/${selectedBrand.brand_name}`
            : "/home",
        component: <Home />,
      },
      {
        key: "brand-manager",
        type: "collapse",
        name: "Brand Manager",
        icon: <Icon fontSize="small">dashboard_customize</Icon>,
        route: "/brand-manager",
        component: <BrandManager />,
      },
      {
        key: "data-hub",
        type: "collapse",
        name: "Data Hub",
        icon: <Icon fontSize="small">device_hub</Icon>,
        route: selectedBrand && selectedRefresh
            ? `/data-hub/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/dataReadiness`
            : "/data-hub",
        component: <DataHub />,
      },
      {
        key: "data-validation",
        type: "collapse",
        name: "Data Validation",
        icon: <Icon fontSize="small">fact_check</Icon>,
        route: selectedBrand && selectedRefresh
            ? `/data-validation/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/sales`
            : "/data-validation",
        component: <DataValidation />,
      },
      {
        key: "modeling",
        type: "collapse",
        name: "Modeling",
        icon: <Icon fontSize="small">dataset</Icon>,
        route: selectedBrand && selectedRefresh
            ? `/modeling/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/modelingSetup`
            : "/modeling",
        component: <Modeling />,
      },
      {
        key: "ai-insights",
        type: "collapse",
        name: "AI Insights",
        icon: <Icon fontSize="small">insights</Icon>,
        route: selectedBrand && selectedRefresh
            ? `/ai-insights/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/exec`
            : "/ai-insights",
        component: <AIInsights />,
      },
      {
        key: "scenario-planner",
        type: "collapse",
        name: "Scenario Planner",
        icon: <Icon fontSize="small">business_center</Icon>,
        route: selectedBrand && selectedRefresh
            ? `/scenario-planner/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}`
            : "/scenario-planner",
        component: <ScenarioPlanner />,
      },
      {
        key: "account",
        type: "collapse",
        name: "Account",
        icon: <Icon fontSize="small">person</Icon>,
        route: accountUrl + '/account',
      },
      {
        key: "logout",
        type: "collapse",
        name: "Log Out",
        icon: <Icon fontSize="small">logout</Icon>,
        route: "/authentication/logout",
        component: <LogOut />,
      }
    ];

    return routes;
};

export default RoutesTrial;
