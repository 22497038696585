import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, Typography, Box } from '@mui/material';
import { useBrand } from 'layouts/utils/BrandContext';
import ModelingSetupTab from 'layouts/modeling/components/ModelingSetupTab';
import ModelsResultsTab from 'layouts/modeling/components/ModelsResultsTab';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';

function Modeling() {
  const { selectedBrand, selectedRefresh, selectedFile } = useBrand();
  const { brandName, refreshName, tab } = useParams();
  const navigate = useNavigate();

  const tabMapping = {
    modelingSetup: 0,
    modelsResults: 1,
  };

  const [tabValue, setTabValue] = useState(tabMapping[tab] || 0)

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    // Get the key from the tabMapping and navigate to the correct URL
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/modeling/${brandName}/${refreshName}/${selectedTab}`);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

   const breadcrumb = [
      ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{ label: 'Brand: No Brand Selected', link: null }]),
      ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand?.brand_name}/${selectedRefresh.refresh_name}` }] : [{ label: 'Refresh: No Refresh Selected', link: null }]),
      ...(selectedRefresh && selectedRefresh.selectedFile ? [{ label: `File: ${selectedRefresh.selectedFile.file.file_name}`, link: null }] : [{ label: 'File: No File Selected', link: null }])
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

        <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                    label="Modeling Setup"
                    icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>dataset</Icon> }
                    style={tabStyle(0)}
                />
               <Tab
                    label="Model Results"
                    icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>checklist_rtl</Icon>}
                    style={tabStyle(1)}
                />
                 </Tabs>
           <BreadcrumbsComponent breadcrumb={breadcrumb}/>
          </AppBar>

         {tabValue === 0 && <ModelingSetupTab setTabValue={setTabValue}/>}
         {tabValue === 1 && <ModelsResultsTab refresh={selectedRefresh} />}
      <Footer />
    </DashboardLayout>
  );
}

export default Modeling;

