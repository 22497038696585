import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import MDTypography from 'components/MDTypography';
import 'chartjs-adapter-moment';
import "assets/css/slider.css";
import MDBox from 'components/MDBox';
import { useBrand } from 'layouts/utils/BrandContext';
import {
    RangeNavigatorComponent,
    AreaSeries,
    DateTime,
    Inject,
    RangeTooltip,
    RangenavigatorSeriesDirective,
    RangenavigatorSeriesCollectionDirective,
    PeriodSelector
} from '@syncfusion/ej2-react-charts';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

function MarketingGraph({ data }) {
    const { configurationDataUserSelection } = useBrand();
    const channels = useMemo(() => configurationDataUserSelection?.adsChannels || [], [configurationDataUserSelection]);
    const metrics = configurationDataUserSelection?.adsSpendMetric || [];
    const adsVariable = configurationDataUserSelection?.adsVariable?.[0] || '';
    const date_column = configurationDataUserSelection?.date?.[0] || '';

    const [selectedMetric, setSelectedMetric] = useState(metrics[0] || '');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
    const [uniqueChannels, setUniqueChannels] = useState([]);
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    const previousRangeRef = useRef(selectedRange); // Track previous range

    const safeData = useMemo(() => {
        return Array.isArray(data) ? data : [];
    }, [data]);

    const parseDate = (dateString) => {
        if (!dateString || typeof dateString !== 'string') return null;
        const parsedDate = new Date(dateString.split('T')[0]);
        return isNaN(parsedDate) ? null : parsedDate;
    };

    const getTransformedData = useCallback(() => {
        // Group data by channel and date to avoid multiple lines for the same channel
        const groupedData = safeData.reduce((acc, item) => {
            const date = parseDate(item[date_column]);
            const channel = item[adsVariable];

            if (!date || !channel || (selectedChannel !== 'All' && channel !== selectedChannel)) {
                return acc;
            }

            const key = `${channel}-${date.getTime()}`; // Use channel and date as a unique key
            if (!acc[key]) {
                acc[key] = {
                    x: date,
                    y: 0,
                    channel,
                    count: 0
                };
            }

            // Aggregate values for the selected metric
            if (typeof item[selectedMetric] === 'number') {
                acc[key].y += item[selectedMetric];
                acc[key].count += 1;
            }

            return acc;
        }, {});

        // Final array of aggregated data points
        return Object.values(groupedData).map(point => ({
            x: point.x,
            y: point.y / point.count, // Average value for the channel on that date
            channel: point.channel
        }));
    }, [safeData, selectedChannel, adsVariable, date_column, selectedMetric]);

    const generateChartData = useCallback(() => {
        const transformedData = getTransformedData();

        if (!transformedData.length) {
            return { labels: [], datasets: [] };
        }

        const uniqueDates = [...new Set(transformedData.map(item => item.x))].sort((a, b) => a - b);
        const channelColors = uniqueChannels.reduce((acc, channel) => {
            acc[channel] = getRandomColor();
            return acc;
        }, {});

        const datasets = uniqueChannels.map(channel => {
            if (selectedChannel !== 'All' && selectedChannel !== channel) return null;
            const channelData = transformedData.filter(item => item.x && item.x instanceof Date);
            return {
                label: channel,
                data: channelData.map(point => ({ x: point.x, y: point.y })),
                fill: false,
                borderColor: channelColors[channel],
                backgroundColor: channelColors[channel],
            };
        }).filter(dataset => dataset);

        return { labels: uniqueDates, datasets };
    }, [getTransformedData, selectedChannel, uniqueChannels]);

    useEffect(() => {
        setChartData(generateChartData());
    }, [generateChartData]);

    useEffect(() => {
        if (safeData.length === 0) return;
        setUniqueChannels([...new Set(safeData.map(item => item[adsVariable]))].filter(channel => channels.includes(channel)));
    }, [safeData, channels, adsVariable, date_column]);


    const handleRangeChange = (args) => {
        const newStartDate = new Date(args.start);  // Convert to Date object
        const newEndDate = new Date(args.end);      // Convert to Date object

        // Comparison logic to avoid unnecessary updates
        const [prevStart, prevEnd] = previousRangeRef.current;
        if (newStartDate.getTime() !== prevStart.getTime() || newEndDate.getTime() !== prevEnd.getTime()) {
            setSelectedRange([newStartDate, newEndDate]);
            previousRangeRef.current = [newStartDate, newEndDate]; // Update previous range
        }
    };

    useEffect(() => {
        const filteredData = safeData.filter(item => {
            const itemDate = parseDate(item[date_column]);
            return itemDate && itemDate >= selectedRange[0] && itemDate <= selectedRange[1];
        });
        setChartData(generateChartData(filteredData));
    }, [selectedRange, generateChartData]);

    if (safeData.length === 0) {
        return <MDTypography variant="body2">No Marketing data available.</MDTypography>;
    }

    const periodselector = {
        position: 'Top',
        enable: true,
        height: 50,
        alignment: 'Near',
        periods: [
            { text: '1M', interval: 1, intervalType: 'Months' },
            { text: '3M', interval: 3, intervalType: 'Months' },
            { text: '6M', interval: 6, intervalType: 'Months' },
            { text: 'YTD', intervalType: 'Years' },
            { text: '1Y', interval: 1, intervalType: 'Years' },
            { text: '2Y', interval: 2, intervalType: 'Years' },
            { text: 'All', intervalType: 'Years', selected: true }
        ],
    };

    // Setting the minimum and maximum x-axis values for the chart based on selectedRange
    const lineOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM DD, YY'
                    },
                    tooltipFormat: 'll'
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                min: selectedRange[0], // Set the minimum value for the x-axis
                max: selectedRange[1], // Set the maximum value for the x-axis
            },
            y: {
                title: {
                    display: true,
                    text: selectedMetric
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Marketing Data'
            }
        }
    };

    return (
        <MDBox>
            <MDTypography variant="h5">{`${selectedMetric} Over Time by ${adsVariable}`}</MDTypography>
            <select className="style-select select-spacing" onChange={(e) => setSelectedMetric(e.target.value)} value={selectedMetric || (metrics.length > 0 ? metrics[0] : '')}>
                {metrics.map((metric, index) => (
                    <option key={index} value={metric}>{metric}</option>
                ))}
            </select>
            <select className="style-select select-spacing" onChange={(e) => setSelectedChannel(e.target.value)} value={selectedChannel}>
                <option value="All">All Channels</option>
                {uniqueChannels.map(channel => (
                    <option key={channel} value={channel}>{channel}</option>
                ))}
            </select>
            {chartData?.datasets?.length > 0 ? (
            <>
                <Line height="110px" data={chartData} options={lineOptions} />
                <br />
                <RangeNavigatorComponent
                    id='rangenavigator'
                    valueType='DateTime'
                    labelFormat='MMM-yy'
                    value={selectedRange}
                    tooltip={{
                        enable: true,
                        format: 'yyyy/MM/dd',
                        displayMode: 'Always',
                        // Fallback to ensure the tooltip only renders when the component is fully mounted
                        animation: { enable: true }
                    }}
                    periodSelectorSettings={periodselector}
                    changed={handleRangeChange}
                    allowSnapping={true}
                >
                    <Inject services={[AreaSeries, DateTime, RangeTooltip, PeriodSelector]} />
                    <RangenavigatorSeriesCollectionDirective>
                        <RangenavigatorSeriesDirective
                            dataSource={getTransformedData()}
                            xName='x'
                            yName='y'
                            type='Area'
                            width={2}
                        />
                    </RangenavigatorSeriesCollectionDirective>
                </RangeNavigatorComponent>
            </>
        ) : (
            <MDTypography variant="body2">No data available for the chart.</MDTypography>
        )}
        </MDBox>
    );
}

// Function to generate random colors for each channel
function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export default MarketingGraph;
