import React, { useState, useEffect } from 'react';
import { useAuthInfo } from "@propelauth/react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, CircularProgress, TextField, IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "assets/Tables/DataTable";
import { format } from 'date-fns';
import AddRefreshDialog from "./AddRefreshDialog";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useBrand } from 'layouts/utils/BrandContext';

const RefreshList = ({ onRefreshUpdate, onFetchFilesVersions }) => {
  const { setSelectedRefresh, selectedBrand, selectedRefresh, saveContext } = useBrand();
  const [open, setOpen] = useState(false);
  const [refreshes, setRefreshes] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const [editingRefresh, setEditingRefresh] = useState(null);
  const [editRefreshName, setEditRefreshName] = useState('');
  const [oldRefreshName, setOldRefreshName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refreshToDelete, setRefreshToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const auth = useAuthInfo();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
 };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCreateRefresh = () => {
    setOpen(true);
  };

  const handleSuccess = (newRefresh) => {
    setRefreshes((prevRefreshes) => [...prevRefreshes, newRefresh]);
    setAlert({ show: true, message: 'Refresh created successfully!', color: 'success' });
    setOpenDialog(true);
  };

  const confirmDeleteOpen = (refresh) => {
    setRefreshToDelete(refresh);
    setConfirmDelete(true);
  };

  const confirmDeleteClose = () => {
    setRefreshToDelete(null);
    setConfirmDelete(false);
  };

  const handleDelete = async () => {
    if (refreshToDelete) {
      setLoading(true);
      const formData = new FormData();
      formData.append('brand_name', selectedBrand.brand_name);
      formData.append('refresh_name', refreshToDelete.refresh_name);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const fullUrl = `${baseUrl}/brands/delete_refresh`;
      try {
        const response = await axios.post(fullUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${auth.accessToken}`
          },
        });
        if (response.data) {
          setRefreshes(refreshes.filter(r => r.refresh_name !== refreshToDelete.refresh_name));
          setAlert({ show: true, message: response.data.message, color: 'success' });
          setOpenDialog(true);
          saveContext({ selectedBrand, selectedRefresh: null, configurationData: null });
        }
      } catch (error) {
        console.error('Error deleting refresh:', error);
        setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
        setOpenDialog(true);
      } finally {
        confirmDeleteClose();
        setLoading(false);
      }
    }
  };

  const handleEdit = (refresh) => {
    setEditingRefresh(refresh);
    setEditRefreshName(refresh.refresh_name);
    setOldRefreshName(refresh.refresh_name);
  };

  const handleView = (refresh) => {
    setSelectedRefresh(refresh);
    const contextData = {
      selectedBrand,
      selectedRefresh: refresh,
      configurationData: refresh.UserSelection
    };
    saveContext(contextData);
    navigate(`/brand-manager/${selectedBrand.brand_name}/${refresh.refresh_name}`);
  };

  const handleEditSave = async () => {
    if (editingRefresh) {
      setLoading(true);
      const formData = new FormData();
      formData.append('old_refresh_name', oldRefreshName);
      formData.append('refresh_name', editRefreshName);
      formData.append('brand_name', selectedBrand.brand_name);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const fullUrl = `${baseUrl}/brands/update_refresh`;
      try {
        const response = await axios.post(fullUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${auth.accessToken}`
          },
        });
        if (response.data) {
          setRefreshes(refreshes.map(r => r.refresh_name === oldRefreshName ? { ...r, refresh_name: editRefreshName } : r));
          setAlert({ show: true, message: response.data.message, color: 'success' });
          setOpenDialog(true);

          if (selectedRefresh?.refresh_name === oldRefreshName) {
            const updatedRefresh = { ...selectedRefresh, refresh_name: editRefreshName };
            setSelectedRefresh(updatedRefresh);
            saveContext({ selectedBrand, selectedRefresh: updatedRefresh, configurationData: null });
          }

          if (onRefreshUpdate && onFetchFilesVersions && selectedRefresh?.refresh_name === oldRefreshName) {
            await onFetchFilesVersions();
            await onRefreshUpdate();
          }
        }
      } catch (error) {
        console.error('Error updating refresh:', error);
        setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
        setOpenDialog(true);
      } finally {
        setEditingRefresh(null);
        setLoading(false);
      }
    }
  };

  const fetchRefreshes = async () => {
    setLoading(true);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/brands/get_refreshes?brand_name=${encodeURIComponent(selectedBrand.brand_name)}`;
    try {
      const response = await axios.get(fullUrl, {
        headers: {
          "Authorization": `Bearer ${auth.accessToken}`
        },
      });

      if (response.data && Array.isArray(response.data.refreshes)) {
        setRefreshes(response.data.refreshes);
      } else {
        setRefreshes([]);
      }
    } catch (error) {
      console.error('Error getting all refreshes:', error);
      setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRefreshes();
  }, []);

  const handleModelResults = (refresh) => {
    setSelectedRefresh(refresh);
    const contextData = {
      selectedBrand,
      selectedRefresh: refresh,
      configurationData: refresh.UserSelection
    };
    saveContext(contextData);
    navigate(`/ai-insights/${selectedBrand.brand_name}/${refresh.refresh_name}/exec`);
  };

  const handleRowClick = (refresh) => {
    handleView(refresh);
  };

  const columns = [
      {
        Header: <MDTypography fontWeight="bold" variant="h6">Name</MDTypography>,
        accessor: "refresh_name",
      },
      {
        Header: <MDTypography fontWeight="bold" variant="h6">Created On</MDTypography>,
        accessor: "created_at",
        Cell: ({ value }) => format(new Date(value), 'MMMM do, yyyy h:mm a'),
      },
      {
        Header: <MDTypography fontWeight="bold" variant="h6">Executive Summary</MDTypography>,
        accessor: "has_model_results",
        Cell: ({ row }) => (
          <Button
                variant="contained"
                color="dark"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModelResults(row.original);
                }}
                disabled={!row.original.has_model_results}
                sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
              >
              {row.original.has_model_results ? 'View Results' : 'No Results'}
           </Button>
        ),
      },
      {
        Header: <MDTypography fontWeight="bold" variant="h6">Actions</MDTypography>,
        accessor: "actions",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="flex-end">
            <Tooltip title="Edit Refresh">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(row.original);
                }}
                sx={{ color: '#006400' }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Refresh">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDeleteOpen(row.original);
                }}
                sx={{ color: '#C31328' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      },
   ];

  const rows = refreshes.map(refresh => ({
    ...refresh,
    actions: '',
    rowProps: {
      onClick: () => handleRowClick(refresh),
      style: { cursor: 'pointer' },
    },
  }));

  return (
    <>
      <MDBox display="flex" justifyContent="flex-end" mr={2} mb={2}>
        <MDButton
          variant="contained"
          color="dark"
          startIcon={<AddIcon />}
          onClick={handleCreateRefresh}
        >
          Add Refresh
        </MDButton>
      </MDBox>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{alert.color === 'success' ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
          {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary" sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDelete} onClose={confirmDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Refresh and its associated metadata?
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDeleteClose} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>Cancel</Button>
          <Button onClick={handleDelete} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>Delete</Button>
        </DialogActions>
      </Dialog>
      {loading ? (
       <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress sx={{ color: blue[500] }} />
          <MDTypography variant="body2" sx={{ mt: 2 }}>Loading refreshes...</MDTypography>
        </MDBox>
      ) : (
        <MDBox>
          <div className="data-table-wrapper">
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15] }}
            showTotalEntries={true}
            rowProps={(row) => row.rowProps}
          />
         </div>
        </MDBox>
      )}
      <AddRefreshDialog open={open} onClose={handleClose} onSuccess={handleSuccess} selectedBrand={selectedBrand} />
      <Dialog open={Boolean(editingRefresh)} onClose={() => setEditingRefresh(null)}>
        <DialogTitle>Edit Refresh Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Refresh Name"
            type="text"
            fullWidth
            value={editRefreshName}
            onChange={(e) => setEditRefreshName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingRefresh(null)} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>Cancel</Button>
          <Button onClick={handleEditSave} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RefreshList;
