import React, { useEffect, useState } from 'react';
import { Alert, Button, Tooltip, Card, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress, IconButton } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { blue } from '@mui/material/colors';
import axios from 'axios';
import MDTypography from 'components/MDTypography';
import { useAuthInfo } from "@propelauth/react";
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import { useSignedUrl } from 'hooks/useSignedUrl';
import { useBrand } from 'layouts/utils/BrandContext';

const DeckBuilderTab = () => {
    const { loadContext, saveContext, selectedBrand, selectedRefresh, user_email, championModel, setChampionModel } = useBrand();
    const { brandName, refreshName, tab } = useParams();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [generatedFileName, setGeneratedFileName] = useState(null);
    const [slideThumbnails, setSlideThumbnails] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [thumbnailsLoading, setThumbnailsLoading] = useState(false);
    const navigate = useNavigate();
    const auth = useAuthInfo();

    const showAlert = (message, color = "info") => {
        setAlert({ show: true, message, color });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        const fetchContext = async () => {
          if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
            await loadContext(brandName, refreshName);
          }
        };
        fetchContext();
    }, [brandName, refreshName, loadContext]);

    // Separate effect to handle state updates after `selectedBrand` and `selectedRefresh` are set
    useEffect(() => {
        if (selectedBrand && selectedRefresh && championModel?.slide_deck_url) {
            // Set the slide deck for the champion model if already defined.
            setGeneratedFileName(championModel.slide_deck_url);

            const fetchThumbnails = async () => {
                setThumbnailsLoading(true);
                const queryParams = new URLSearchParams({
                    file_path: `${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/${championModel.slide_deck_url}`,
                    user_email: user_email,
                    brand_name: selectedBrand.brand_name,
                    refresh_name: selectedRefresh.refresh_name,
                }).toString();
                const baseUrl = process.env.REACT_APP_API_BASE_URL;
                const fullUrl = `${baseUrl}/deck/get_slide_thumbnails?${queryParams}`;
                try {
                    const thumbnailsResponse = await axios.get(fullUrl, {
                        headers: { "Accept": "application/json" }
                    });
                    if (thumbnailsResponse.status === 200) {
                        setSlideThumbnails(thumbnailsResponse.data.thumbnails);
                    }
                } catch (error) {
                    console.error('Error fetching thumbnails:', error);
                } finally {
                    setThumbnailsLoading(false); // Stop loading thumbnails
                }
            };

            if (!slideThumbnails.length && championModel?.slide_deck_url) {
                fetchThumbnails();
            }
        }
    }, [selectedBrand, selectedRefresh, championModel, slideThumbnails.length]);

    const downloadLink = generatedFileName
        ? `https://storage.cloud.google.com/uploaded_assets/${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/${generatedFileName}`
        : null;

    const signedDownloadLink = useSignedUrl(downloadLink);

    const handleDownloadSlideDeck = async () => {
        console.log("signedDownloadLink", signedDownloadLink);
        if (signedDownloadLink) {
            try {
                const response = await axios.get(signedDownloadLink, {
                    responseType: 'blob',
                });
                saveAs(response.data, generatedFileName);
            } catch (error) {
                console.error('Error downloading the file:', error);
                showAlert('Error downloading the file', 'error');
            }
        } else {
            showAlert('Error: Signed URL not available', 'error');
        }
    };

    const handleSelectBrandNavigation = () => {
         navigate(`/brand-manager/`);
    };

    const handleGenerateDeck = async () => {
        setLoading(true);
        setLoadingMessage('Generating Slide Deck...');
        const inputs_file = `https://storage.cloud.google.com/uploaded_assets/${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/model_inputs.json`;
        const outputs_file = `https://storage.cloud.google.com/uploaded_assets/${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/${championModel.model_type}_outputs.json`;

        const queryParams = new URLSearchParams({
            inputs_file: inputs_file,
            outputs_file: outputs_file,
            user_email: user_email,
            brand_name: selectedBrand.brand_name,
            refresh_name: selectedRefresh.refresh_name,
            model_type: championModel.model_type
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/deck/generate_slide_deck?${queryParams}`;
        try {
            const response = await axios.post(
                fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
            if (response.status === 200) {
                 const log_message = `Slide deck generated: ${response.data.data.filename}`;
                 console.log(log_message);
                 showAlert(log_message, "info");
                 setGeneratedFileName(response.data.data.filename);
                 setChampionModel((prevModel) => ({
                    ...prevModel,
                    slide_deck_url: `https://storage.cloud.google.com/uploaded_assets/${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/${response.data.data.filename}`
                 }));
                 // Save the context
                 const updatedChampionModel = {
                    model_type: championModel.model_type,
                    highest_accuracy: championModel.highest_accuracy,
                    best_roi: championModel.best_roi,
                    slide_deck_url: response.data.data.filename
                 };
                 setChampionModel(updatedChampionModel);

                 const contextData = {
                    championModel: updatedChampionModel,
                 };
                 saveContext(contextData);

                 setLoadingMessage('Generating Thumbnails...');
                 const thumbnailParams = new URLSearchParams({
                        file_path: `${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/${response.data.data.filename}`,
                        user_email: user_email,
                        brand_name: selectedBrand.brand_name,
                        refresh_name: selectedRefresh.refresh_name,
                 }).toString();
                 const fullThumbnailUrl = `${baseUrl}/deck/get_slide_thumbnails?${thumbnailParams}`;
                 const thumbnailsResponse = await axios.get(
                     fullThumbnailUrl, {}, {headers: {"Accept": "application/json"}}
                 );
                 if (thumbnailsResponse.status === 200) {
                    setSlideThumbnails(thumbnailsResponse.data.thumbnails);
                 }
            } else {
                showAlert(`Error generating deck: ${response.statusText || 'Unknown error'}`, 'error');
            }
        } catch (error) {
            let errorMessage = 'Error generating deck';
            if (error.response && error.response.data && error.response.data.detail) {
                errorMessage = `Error: ${error.response.data.detail}`;
            } else if (error.message) {
                errorMessage = `Error: ${error.message}`;
            }
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
            setLoadingMessage('');
        }
    };

    return (
     <>
      <Dialog open={openDialog} aria-labelledby="Modeling" aria-describedby="run-models" onClose={handleCloseDialog}>
                <DialogTitle>Deck Builder</DialogTitle>
                <DialogContent>
                    {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
      </Dialog>
      <MDBox pt={3} pb={3}>
        <MDTypography variant="h3">
            Insight Deck Builder
             <Tooltip title = "Download Slide Deck ">
                <span>
                <IconButton
                    onClick={handleDownloadSlideDeck}
                    disabled={!generatedFileName}
                >
                 <DownloadIcon />
               </IconButton>
               </span>
           </Tooltip>
        </MDTypography>
        <Card sx={{ mt: 2 }}>
            <MDTypography variant="body2" p={2}>
                {championModel?.model_type ? (
                    <>
                        <MDTypography variant="h4" align="left" gutterBottom>
                            Selected Model: {championModel?.model_type ? championModel?.model_type.toUpperCase() : 'N/A'}
                        </MDTypography>
                        <MDBox display="flex" justifyContent="space-evenly" alignItems="center">
                            <MDBox textAlign="center">
                                <MDTypography variant="h6">Best ROI</MDTypography>
                                <MDTypography variant="h4">{championModel?.best_roi || 'N/A'}x</MDTypography>
                            </MDBox>
                            <MDBox textAlign="center">
                                <MDTypography variant="h6">Highest Accuracy</MDTypography>
                                <MDTypography variant="h4">{championModel?.highest_accuracy || 'N/A'}%</MDTypography>
                            </MDBox>
                        </MDBox>
                    </>
                ) : (
                  <>
                    <MDTypography variant="h6" color="warning" align="center">
                        Select first a Brand and Refresh. Then go to <i>Modeling > Model Results</i> to select the best model.
                    </MDTypography>
                    <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                        <MDButton
                                variant="contained"
                                color="dark"
                                onClick={handleSelectBrandNavigation}
                            >
                                Select a Brand
                        </MDButton>
                        </MDBox>
                  </>
                )}
               <MDBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 2, mb: 2 }}>
                  {loading && (
                    <MDBox display="flex" flexDirection="column" alignItems="center" mb={2}>
                      <CircularProgress sx={{ color: blue[500], marginBottom: '1rem' }} size={24} color="inherit" />
                      <MDTypography variant="body2">{loadingMessage}</MDTypography>
                    </MDBox>
                  )}
                  <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleGenerateDeck}
                      disabled={!!generatedFileName || !championModel?.model_type || loading}
                    >
                      Generate Slide Deck
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleDownloadSlideDeck}
                      disabled={!generatedFileName}
                    >
                      Download Slide Deck
                    </MDButton>
                  </MDBox>
                </MDBox>
            </MDTypography>
        </Card>
        {thumbnailsLoading ? (
            <MDBox display="flex" justifyContent="center" mt={3} mb={3}>
                <CircularProgress sx={{ color: blue[500] }} size={24} color="inherit" />
                <MDTypography  variant="body2" sx={{ ml: 2 }}>Fetching Thumbnails...</MDTypography>
            </MDBox>
        ) : (
         generatedFileName && slideThumbnails.length > 0 && (
              <Card sx={{ mt: 3, mb: 3, p: 2 }}>
                <MDBox>
                    <MDTypography variant="h5">Slide Deck Thumbnails</MDTypography>
                    <MDBox  sx={{ mt: 2, mb:2}} display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
                        {slideThumbnails.map((thumbnailUrl, index) => (
                           <Tooltip key={index} title={`Slide ${index + 1}`} arrow>
                            <img
                                key={index}
                                src={thumbnailUrl}
                                alt={`Slide ${index + 1}`}
                                style={{
                                    width: '150px',
                                    height: 'auto',
                                    borderRadius: '4px',
                                    border: '1px solid #000',
                                    padding: '4px'
                                }}
                            />
                            </Tooltip>
                        ))}
                    </MDBox>
                </MDBox>
             </Card>
            )
        )}
    </MDBox>
    </>
    );
};

export default DeckBuilderTab;
