import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Card, MenuItem, Select, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';
import { MaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useBrand } from 'layouts/utils/BrandContext';

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e0e0',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#F1F1F1',
          '&.MuiTableRow-head': {
            backgroundColor: '#F1F1F1',
          },
          '&:hover': {
            backgroundColor: '#cccccc',
          },
        },
        root: {
          '&.MuiTableRow-root': {
            backgroundColor: '#ffffff',
          },
          '&:hover': {
            backgroundColor: '#cccccc',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#333',
        },
      },
    },
  },
});

// Helper function to format currency values
const formatCurrency = (value) => {
  const formattedValue = Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 0 });
  return value < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
};

const revenueColumns = [
  { accessorKey: 'Category', header: 'Group', size: 40, },
  { accessorKey: 'Variable', header: 'Variable', size: 40, },
  { accessorKey: 'Target Variable', header: 'Target Variable',  size: 40, },
  {
    accessorKey: 'Ad Spend',
    header: 'Ad Spend',
    Cell: ({ cell }) => formatCurrency(cell.getValue()),
  },
  {
    accessorKey: 'Contribution',
    header: 'Contribution',
    Cell: ({ cell }) => formatCurrency(cell.getValue()),
  },
  {
    accessorKey: 'Marginal Contribution',
    size: 40,
    header: 'Marginal Contribution',
    Cell: ({ cell }) => (cell.getValue() != null ? cell.getValue().toFixed(2) : 'N/A'),
  },
  {
    accessorKey: 'ROI',
    header: 'ROI',
    size: 40,
    Cell: ({ cell }) => {
      const roiValue = parseFloat(cell.getValue());
      return isNaN(roiValue) ? 'N/A' : roiValue.toFixed(2);
    },
  },
  {
    accessorKey: 'MROI',
    header: 'MROI',
    size: 40,
    Cell: ({ cell }) => {
      const mroiValue = parseFloat(cell.getValue());
      return isNaN(mroiValue) ? 'N/A' : mroiValue.toFixed(2);
    },
  },
];

const ExecSummaryTab = React.memo(({ refresh }) => {
    const [errors, setErrors] = useState({});
    const [modelsOutputs, setModelsOutputs] = useState({});
    const [selectedModelType, setSelectedModelType] = useState('');
    const [loading, setLoading] = useState(true); // Loading state
    const { championModel, setChampionModel } = useBrand();
    const auth = useAuthInfo();

    const handleModelTypeChange = (event) => {
      const newModelType = event.target.value;
      setSelectedModelType(newModelType);
      const outputs = {};
      refresh['Models_results'].forEach(file => {
        outputs[file.model_type] = file.file_url;
      });
      fetchModelsOutputs(outputs, newModelType);
    };

    const fetchModelsOutputs = useCallback(async (modelsOutputs, model_type) => {
        setLoading(true);
        const queryParams = new URLSearchParams({
          models_outputs: JSON.stringify(modelsOutputs),
          champion_model_type: model_type
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/model/display_model_exec_results?${queryParams}`;
        try {
          const response = await axios.post(
            fullUrl, {},
            {
              headers: {
                "Authorization": `Bearer ${auth.accessToken}`,
                "Accept": "application/json",
              },
            }
          );
          if (response.status === 200 && response.data.success) {
            setModelsOutputs(response.data.data);
            if (response.data.data['champion_model_data'] && Object.keys(response.data.data['champion_model_data']).length > 0) {
                setChampionModel(response.data.data['champion_model_data']);
            }
          } else {
            const errorDetail = response.data.detail || 'Unknown error';
            setErrors(prevState => ({ ...prevState, general: errorDetail }));
          }
        } catch (error) {
          const errorDetail = error.response ? error.response.data.detail : error.message;
          setErrors(prevState => ({ ...prevState, general: errorDetail }));
        } finally {
          setLoading(false);
        }
    }, [auth.accessToken]);

    useEffect(() => {
        if (refresh && refresh['Models_results']) {
          const modelsOutputs = {};
          refresh['Models_results'].forEach(file => {
            modelsOutputs[file.model_type] = file.file_url;
          });
          const model_type = championModel?.model_type || '';
          setSelectedModelType(model_type);
          fetchModelsOutputs(modelsOutputs, model_type);
        }
    }, [refresh, fetchModelsOutputs]);

    if (loading && refresh) {
      return (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress sx={{ color: blue[500] }}/>
        </MDBox>
      );
    }

    if (!refresh || !modelsOutputs?.executive_results) return null;

    const { executive_results, revenue_sources, champion_model_data } = modelsOutputs;

    const textFieldStyles = {
        margin: 'dense',
        variant: 'outlined',
        fullWidth: true,
        sx: {
            '& .MuiInputBase-root': {
                height: '44px',
            },
        },
    };

    return (
      <MDBox p={3} pb={3} pr={5}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h3">Executive Summary</MDTypography>
          <MDBox sx={{ width: '200px', ml: 2 }}>
            <FormControl variant="outlined" margin="dense"  {...textFieldStyles} >
              <InputLabel>Select Model Type</InputLabel>
              <Select
                name="Model Type"
                value={selectedModelType}
                onChange={handleModelTypeChange}
                label="Select Model Type"
                IconComponent={ArrowDropDownIcon}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiSelect-icon': {
                    display: 'block',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                }}
              >
                {refresh['Models_results'] && refresh['Models_results'].map((file) => (
                  <MenuItem key={file.model_type} value={file.model_type}>
                    {file.model_type.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>

        <Card sx={{ mt: 2, mb: 3, p: 3, backgroundColor: '#e8f4fd', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <MDBox display="flex" justifyContent="space-between" mb={2}>
            <MDBox textAlign="center">
              <MDTypography variant="h6">Spend</MDTypography>
              <MDTypography variant="h4">{formatCurrency(executive_results.Spend)}</MDTypography>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="h6">ROI</MDTypography>
              <MDTypography variant="h4">{`${executive_results.ROI}x`}</MDTypography>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="h6">% Incremental</MDTypography>
              <MDTypography variant="h4">{`${executive_results['% Incremental']}%`}</MDTypography>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="h6">Total Sales</MDTypography>
              <MDTypography variant="h4">{formatCurrency(executive_results['Total Sales'])}</MDTypography>
            </MDBox>
          </MDBox>
        </Card>

        <MDTypography variant="h4" gutterBottom>Revenue Sources</MDTypography>
        <Card>
          <MDBox p={3}>
            <ThemeProvider theme={theme}>
              <MaterialReactTable
                columns={revenueColumns}
                data={revenue_sources || []}
                getSubRows={(row) => row.subRows || []}
                enableExpanding
              />
            </ThemeProvider>
          </MDBox>
        </Card>
      </MDBox>
    );
  });

export default ExecSummaryTab;